import { ActionsService } from '@services/api/actions/actions.service';
import { NewsModel } from '@models/sports_minute';
import { SportsMinuteService } from '@services/api/sports-minute/sports-minute.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Helper } from '@helpers/helper/helper';
import { Team } from '@models/team.model';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-city',
    templateUrl: './city.component.html',
    styleUrls: ['./city.component.scss']
})
export class CityComponent implements OnInit, OnDestroy {
    @Input() level: number = 1;
    @Input() team: Team;
    @Input() isRefereeHome: boolean = false;
    @Input() isCheerleaderHome: boolean = false;

    public teamStars: string[] = [];
    public notReadNews: boolean = false;
    public notReadNewsCount: number = 0;
    private getNewsSubs: Subscription;

    constructor(
        private helper: Helper,
        private router: Router,
        private sportMinuteService: SportsMinuteService,
        private actionsService: ActionsService
    ) {}

    ngOnInit(): void {
        if (this.team) {
            this.teamStars = this.helper.getTeamStars(this.team.division_position);
        }
        this.getNews();
    }

    ngOnDestroy(): void {
        this.getNewsSubs.unsubscribe();
    }

    goToRepository(): void {
        const userProfile = sessionStorage.getItem('user-profile');
        if (this.team && userProfile === 'trainer') {
            sessionStorage.setItem('last-team-visited', this.team.id);
        }
        this.actionsService.postAction({ action: 'navigate_repository' }).subscribe(() => {});
        this.router.navigate(['repository']);
    }

    goToNews(): void {
        this.actionsService.postAction({ action: 'navigate_news' }).subscribe(() => {});
        this.router.navigate(['sports-minute']);
    }

    getNews(): void {
        this.getNewsSubs = this.sportMinuteService.getNews().subscribe((response: NewsModel[]) => {
            if (response.length) {
                const noticesNotRead = response.filter(notice => !notice.read);
                if (noticesNotRead.length) {
                    this.notReadNews = true;
                    this.notReadNewsCount = noticesNotRead.length;
                }
            }
        });
    }
}
