<div class="city">
    <div class="position-relative">
        <div class="image-wrapper">
            <img [src]="'assets/images/cities/base-city.png'" alt="city">
            <img [src]="'assets/images/cities/level-'+level+'.png'" class="stadium">
        </div>
        <ng-container *ngIf="team || isCheerleaderHome">
            <div class="speech-bubble team pointer" *ngIf="!isRefereeHome && !isCheerleaderHome" [routerLink]="['/team-members/'+team.id]">
                <div class="team-shield" [style.background-image]="'url('+team.picture+')'"></div>
                <span class="semi-bold">{{team.name}}</span>
                <div class="stars-wrapper">
                    <div *ngFor="let starColor of teamStars">
                        <img [src]="'assets/images/icons/'+starColor+'-star.svg'">
                    </div>
                </div>
            </div>
            <div class="speech-bubble team pointer inbox" *ngIf="isRefereeHome" [routerLink]="['/inbox']">
                <img [src]="'assets/images/icons/inbox.svg'">
                <span class="semi-bold">Bandeja de entrada</span>
            </div>
            <div class="speech-bubble ranking pointer" [routerLink]="['/rankings']">
                <img [src]="'assets/images/icons/ranking.svg'">
                <span class="semi-bold" *ngIf="!isRefereeHome && !isCheerleaderHome">{{team.ranking_position}}º ranking</span>
                <span class="semi-bold" *ngIf="isCheerleaderHome || isRefereeHome">Ranking</span>
            </div>
            <div class="speech-bubble reports pointer" (click)="goToNews()">
                <img [src]="'assets/images/icons/reportajes.svg'">
                <span class="semi-bold">Reportajes</span>
                <span *ngIf="notReadNews" class="news-count">{{notReadNewsCount}}</span>
            </div>
            <div class="speech-bubble repository pointer" (click)="goToRepository()">
                <img [src]="'assets/images/icons/repositorio.svg'">
                <span class="semi-bold">Repositorio</span>
            </div>
        </ng-container>
    </div>
</div>