import { Helper } from '@helpers/helper/helper';
import { SeasonsService } from '@services/api/seasons/seasons.service';
import { ReportService } from '@services/api/report/report.service';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EvaluatedQuestion, EvaluationApiPOST, EvaluationForm, EvaluationQP } from '@models/evaluation.model';
import { OptionInteface, RadioButton } from '@models/option.model';
import { EvaluationService } from '@services/api/evaluation/evaluation.service';

@Component({
    selector: 'app-evaluate',
    templateUrl: './evaluate.component.html',
    styleUrls: ['./evaluate.component.scss']
})
export class EvaluateComponent implements OnInit {
    public validReportOptions: OptionInteface[] = [
        { key: 0, value: 'No' },
        { key: 1, value: 'Sí' },
        { key: 2, value: 'Pasar a borrador' }
    ];
    public form: EvaluationForm;
    public valid: boolean = false;
    public isHipo: boolean = false;
    public saveToDraft: boolean = false;

    public evaluatedQuestions: EvaluatedQuestion[] = [];
    public reportId: number;
    public evaluators: any[] = [];
    public evaluationComment: string | null;
    public radioButtonsDefault: RadioButton[] = [
        { key: 'y', value: 'Sí', active: false },
        { key: 'n', value: 'No', active: true }
    ];
    public riskIncidentsOptions: OptionInteface[];
    public selectedRisk: OptionInteface | null = null;
    public riskAlreadyEvaluated: boolean = false;
    public seasonStartDate: string = '';
    public seasonFinishDate: string = '';

    public sending: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private evaluationService: EvaluationService,
        private _snackBar: MatSnackBar,
        private router: Router,
        private reportService: ReportService,
        private seasonService: SeasonsService,
        private helper: Helper
    ) {}

    ngOnInit(): void {
        this.reportId = this.route.snapshot.params.report_id;
        this.getSeasonFinishDate();
    }

    async getSeasonFinishDate(): Promise<void> {
        const { finishDate, startDate } = await this.seasonService.getSeasonDates();
        this.seasonFinishDate = finishDate;
        this.seasonStartDate = startDate;
        this.checkSeasonDate();
    }

    checkSeasonDate(): void {
        if (this.seasonFinishDate && this.seasonStartDate) {
            const isValid = this.helper.isBetweenDates(this.seasonStartDate, this.seasonFinishDate);
            if (isValid) {
                this.getRisksIncidents();
            } else {
                this.router.navigate(['/inbox']);
            }
        } else {
            this.router.navigate(['/inbox']);
        }
    }

    getRisksIncidents(): void {
        this.reportService.getNewReportSelect('riskIncidents', true).subscribe(
            (response: any) => {
                this.riskIncidentsOptions = response
                    .filter(res => res.is_hipo)
                    .map(option => {
                        return { key: option.id, value: option.name };
                    });
                this.loadQuestions();
            },
            error => {
                console.log(error);
            }
        );
    }

    loadQuestions(): void {
        this.evaluationService.getQuestions(this.reportId).subscribe(
            (response: any) => {
                // if the incident is not evaluable, navigate user to inbox
                if (response.state !== 'pending' && response.state !== 'in_review') {
                    this.router.navigate(['/inbox']);
                }
                this.form = new EvaluationForm(response.questions);
                this.evaluators = response.users;
                this.evaluationComment = response.evaluation_incident_comments;
                this.valid = this.evaluators.length > 0 ? true : false;
                if (response.risk_incident_referee) {
                    this.selectedRisk = this.riskIncidentsOptions.find(
                        option => option.key === response.risk_incident_referee.id
                    );
                    if (this.selectedRisk) {
                        this.riskAlreadyEvaluated = true;
                        this.isHipo = true;
                        this.radioButtonsDefault = [
                            { key: 'y', value: 'Sí', active: true },
                            { key: 'n', value: 'No', active: false }
                        ];
                    }
                } else {
                    this.riskAlreadyEvaluated = false;
                }
            },
            error => {
                console.log(error);
            }
        );
    }

    changeValidStatus(event: OptionInteface): void {
        this.valid = event.key === 1 ? true : false;
        this.saveToDraft = event.key === 2 ? true : false;
    }

    setIsHipo(event: RadioButton): void {
        this.isHipo = event.key === 'y' ? true : false;
        if (!this.isHipo) this.selectedRisk = null;
    }

    getQuestionResponse(question: EvaluationQP, event: OptionInteface): void {
        let questionId = question.id;
        let punctuation = event.key;
        let foundQuestion = this.evaluatedQuestions.find(q => q.id === questionId);

        if (foundQuestion) {
            let index = this.evaluatedQuestions.indexOf(foundQuestion);
            if (punctuation !== '-') {
                this.evaluatedQuestions[index].punctuation = parseInt(punctuation.toString());
            } else {
                // delete from array
                this.evaluatedQuestions.splice(index, 1);
            }
        } else {
            if (punctuation !== '-') {
                this.evaluatedQuestions.push({
                    id: questionId,
                    punctuation: parseInt(punctuation.toString())
                });
            }
        }
    }

    setRiskOption(option: OptionInteface): void {
        this.selectedRisk = option;
    }

    sendEvaluation(): void {
        let incident: EvaluationApiPOST = {
            incident: this.reportId,
            is_valid: this.valid,
            is_draft: this.saveToDraft,
            evaluation_questions: [],
            comments: this.evaluationComment
        };

        if (this.sending) return;
        this.sending = true;

        if (this.valid && this.evaluatedQuestions.length > 0) {
            incident['evaluation_questions'] = this.evaluatedQuestions;
            if (this.selectedRisk) {
                incident['risk'] = this.selectedRisk.key;
            }
        }
        if (this.isSecctionCompleted(incident['evaluation_questions'])) {
            this.evaluationService.evaluateIncident(incident).subscribe(
                () => {
                    this.sending = false;
                    const text = this.saveToDraft
                        ? 'Incidencia pasada a borrador correctamente'
                        : 'Evaluación enviada correctamente';
                    this.openSnackBar(text, 'success-snackbar');
                    if (this.saveToDraft) {
                        this.router.navigate(['/inbox']);
                    } else {
                        this.router.navigate(['/report/' + this.reportId]);
                    }
                },
                error => {
                    console.log(error);
                    this.sending = false;
                    const text = this.saveToDraft
                        ? 'Algo ha salido mal al pasar a borrador la incidencia. Inténtalo más tarde.'
                        : 'Algo ha salido mal al enviar la evaluación. Inténtalo más tarde.';
                    this.openSnackBar(text, 'error-snackbar');
                }
            );
        } else {
            this.sending = false;
            this.openSnackBar('Solo se aceptan secciones completas', 'error-snackbar');
        }
    }

    openSnackBar(text: string, snackBarClass: 'success-snackbar' | 'error-snackbar') {
        this._snackBar.open(text, 'X', {
            duration: 3000,
            panelClass: [snackBarClass]
        });
    }

    isSecctionCompleted(questions: any[]): boolean {
        if (!questions) return true;
        let qIds = questions
            .map(q => {
                return q.id;
            })
            .sort();
        let sectionIds = this.form.sections.map(s => {
            return s.questions.map(q => q.id);
        });
        let sectionsCompare = sectionIds.map(sids => {
            return sids.map(id => {
                return qIds.includes(id);
            });
        });

        let canSubmit = sectionsCompare.every(section => {
            const isEdited = section.some((question: boolean) => question);
            return isEdited ? section.every((question: boolean) => question) : true;
        });

        return canSubmit;
    }
}
