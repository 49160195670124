<div class="fv-select-wrapper" [ngClass]="{filter: isFilter, 'with-icon': icon, 'mine': mine}">
    <div class="fv-select" [ngClass]="{disabled: disabled}" (click)="open()">
        <img [src]="'assets/images/icons/'+icon+'.svg'" *ngIf="icon && !fromAPI" class="fv-select-icon">
        <img [src]="icon" *ngIf="icon && fromAPI" class="fv-select-icon">

        <div *ngIf="isFilter" class="select-image">
            <img [src]="mine
                ? 'assets/images/icons/user.svg'
                : 'assets/images/icons/tarjeta-amarilla-roja.svg'" alt="">
        </div>
        <p *ngIf="!selectedItem" class="placeholder">
            {{placeholder}} 
            <span class="placeholder" *ngIf="maxSelectedOptions">
                ({{maxSelectedOptionsText}} {{maxSelectedOptions}})
            </span>
        </p>
        <span *ngIf="selectedItem">{{selectedItem.value}}</span>
        <div class="fv-select__right-wrapper">
            <span class="counter" *ngIf="countRows !== 0">{{'+' + countRows}}</span>
            <img [src]="isFilter
                ? 'assets/images/icons/chevron-down-white.svg'
                : 'assets/images/icons/chevron-down.svg'" class="arrow-down ml-xs">
        </div>

    </div>
    <div class="fv-select-options" [ngClass]="{opened: opened}" [class]="classOptions">
        <div class="searcher-box" *ngIf="searcher">
            <input type="text" #inputSearcher class="searcher" (keyup)="search($event.target.value)">
        </div>
        <div class="option" *ngFor="let option of options" (click)="mode === 'multiple' ? selectOption(option, 'checkbox') : selectOption(option)" [ngClass]="{disabled: option.disabled}">
            {{option.value}}
            <div class="option-checkbox" *ngIf="mode === 'multiple'">
                <input type="checkbox" [checked]="option?.checked" [disabled]="option?.disabled">
            </div>
        </div>
    </div>
    <div class="overlay" *ngIf="opened" (click)="close()"></div>
    <span *ngIf="showValues && selectedValues" class="fv-options-values">{{ selectedValues }}</span>
</div>