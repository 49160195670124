<div class="bg futball-field h-100">
    <div id="ranking-page" class="container page-with-top-bar pb-md full-height">
        <div class="row">
            <div class="col">
                <div class="button-wrapper mt-3">
                    <div class="arrow-back pointer" routerLink="/team"></div>
                    <p class="">Volver</p>
                </div>
            </div>   
        </div>
    
        <div class="row justify-content-center">
            <div class="col col-md-6 col-lg-5 col-xl-4">
    
                    <div class="mt-3 d-flex justify-content-center">
                        <div class="ranking-title">
                            <img
                            class="cup-icon mr-4"
                            src="assets/images/icons/ranking.svg" 
                            alt="Ranking">
                            <p>Rankings</p>
                        </div>
                    </div>
                    <div class="mt-5 ranking-option pointer"
                         routerLink="./total">
                        <div class="option-icon-wrapper">
                            <img class="option-icon"
                            src="assets/images/icons/rank-total.svg"
                            alt="">
                        </div>
                        <p>Ranking total</p>
                    </div>
                    <div class="mt-3 ranking-option second pointer"
                         routerLink="./monthly">
                        <div class="option-icon-wrapper">
                            <img class="option-icon"
                            src="assets/images/icons/rank-monthly.svg"
                            alt="">
                        </div>
                        <p>Ranking mensual</p>
                    </div>
                    <div class="mt-3 ranking-option third pointer"
                         routerLink="./pichichis">
                        <div class="option-icon-wrapper">
                            <img class="option-icon"
                            src="assets/images/icons/rank-pichichi.svg"
                            alt="">
                        </div>
                        <p>Ranking de pichichis</p>
                    </div>
                    
                    <div class="mt-5 d-md-none d-flex justify-content-center">
                        <button class="btn-return" routerLink="/team"> Volver </button>
                    </div>
                </div>
            
        </div>
    
        <div class="d-md-none fixed-bottom logo-wrapper mr-3">
            <img 
            class="logo"
            src="assets/images/logo-serveo.png" 
            alt="Serveo"
            >
        </div>
    </div>
</div>
        
