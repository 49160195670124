<div class="bg futball-field h-100 no-scroll-y">
    <div id="sports-minute-details-page" class="container page-with-top-bar pb-md full-height">
        <div class="row justify-content-center">
            <div class="col offset-md-3">
                <div class="mt-4 d-flex justify-content-center">
                    <div class="reporter-container d-flex-center">
                        <img [src]="imageUrl" alt="Reportera" />
                    </div>
                    <div *ngIf="news" class="mt-2 mr-4 ml-4 slide-container">
                        <div class="slide-date">{{ news.created_at | date : ['dd/MM/yyyy'] }}</div>
                        <div class="live-icon">LIVE</div>
                        <div class="slide-text" [innerHtml]="news.slides[currentSlide].content"></div>

                        <div class="slide-button d-flex-center pointer" (click)="clickNext()">
                            <img src="assets/images/icons/chevron-down-white.svg" alt="Siguiente" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
