<div class="bg futball-field h-100">
    <div class="container full-height green-overlay onboarding">
        <div class="row">
            <div class="col-12 d-flex justify-content-end">
                <img src="assets/images/logo-serveo.png" >
            </div>
            <div class="col-12" *ngIf="onboarding">
                <div class="onboarding-step">
                    <div class="onboarding-step__title mb-sm bold">{{onboarding.steps[onboarding.currentStepIndex].title}}</div>
                    <div class="onboarding-step__image mb-sm" [style.background-image]="'url('+onboarding.steps[onboarding.currentStepIndex].imageUrl+')'"></div>
                    <div class="onboarding-step__description mb-sm">{{onboarding.steps[onboarding.currentStepIndex].description}}</div>
                    <app-button [color]="'white'" [rounded]="true" [block]="true" [textColor]="'dark'" [text]="onboarding.steps[onboarding.currentStepIndex].buttonText" (click)="manageOnboardingActions()"></app-button>
                </div>
                <div class="onboarding-bullets-wrapper mt-md">
                    <div 
                    *ngFor="let step of onboarding.steps; let i = index;" 
                    (click)="onboarding.goTo(i)"
                    class="bullet" [ngClass]="{active: onboarding.currentStepIndex === i}"></div>
                </div>
            </div>
        </div>
    </div>
</div>