import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { RankingService } from '@services/api/ranking/ranking.service';

import { rankingItem } from '@models/ranking.model';
import { OptionInteface } from '@models/option.model';
import { Subscription, Observable } from 'rxjs';
import { filterMonthOptions } from '../repository/filters.conf';

@Component({
    selector: 'app-ranking-individual',
    templateUrl: './ranking-individual.component.html',
    styleUrls: ['./ranking-individual.component.scss']
})
export class RankingIndividualComponent implements OnInit {
    public type: 'total' | 'monthly' | 'pichichis';
    public ranking: rankingItem[] = [];
    public rankingSubs: Subscription;
    public filters: any = {
        month: null,
        year: null,
        division: null
    };
    public filterOptions: OptionInteface[];
    public filterMonthOptions: OptionInteface[] = filterMonthOptions;
    public filterYearOptions: OptionInteface[];
    public YEAR_RANGE: number = 3;
    public currentMonth: number = new Date().getMonth();

    constructor(private route: ActivatedRoute, private router: Router, private rankingService: RankingService) {}

    ngOnInit(): void {
        const params = this.route.snapshot.paramMap;
        this.type = <'total' | 'monthly' | 'pichichis'>params.get('type');
        this.setYearsFilter();
        this.loadItems();
        this.loadDivisions();
    }
    ngOnDestroy(): void {
        if (this.rankingSubs) {
            this.rankingSubs.unsubscribe();
        }
    }

    get rankingName() {
        switch (this.type) {
            case 'total':
                return 'Ranking Total';
            case 'monthly':
                return 'Ranking Mensual';
            case 'pichichis':
            default:
                return 'Ranking de puntos de Pichichis';
        }
    }

    get iconUrl() {
        switch (this.type) {
            case 'total':
                return 'assets/images/icons/rank-total.svg';
            case 'monthly':
                return 'assets/images/icons/rank-monthly.svg';
            case 'pichichis':
            default:
                return 'assets/images/icons/rank-pichichi.svg';
        }
    }

    get lastUpdate() {
        let lastDate = 0;
        this.ranking.forEach(item => {
            const itemTime = new Date(item.updated_at).getTime();
            lastDate = itemTime > lastDate ? itemTime : lastDate;
        });
        if (lastDate > 0) {
            return new Date(lastDate).toUTCString();
        } else {
            return null;
        }
    }

    setYearsFilter(): void {
        const currentYear = new Date().getFullYear();
        this.filterYearOptions = [];
        for (let year = currentYear - this.YEAR_RANGE; year <= currentYear + this.YEAR_RANGE; year++) {
            this.filterYearOptions.push({ key: year, value: year.toString() });
        }
    }

    setRanking(obs: Observable<rankingItem[]>): void {
        this.rankingSubs = obs.subscribe(response => {
            this.ranking = response;
        });
    }

    loadItems(): void {
        switch (this.type) {
            case 'total':
                this.setRanking(this.rankingService.getRanking());
                break;
            case 'monthly':
                this.setRanking(this.rankingService.getRanking(this.currentMonth + 1));
                return;
            case 'pichichis':
                this.setRanking(this.rankingService.getTopScorers());
            default:
                return;
        }
    }

    loadDivisions(): void {
        this.rankingService.getDivisions().subscribe(response => {
            this.filterOptions = response;
            this.filterOptions.unshift({ key: '~clear~', value: '-' });
        });
    }

    getSubFilter(filter: string, key: any) {
        if (key !== '~clear~') {
            this.filters[filter] = key;
        } else {
            if (this.filters[filter]) {
                this.filters[filter] = null;
            }
        }
        this.setRanking(this.rankingService.getRanking(this.filters.month, this.filters.division, this.filters.year));
    }

    clickOnItem(itemId: string): void {
        if (this.type !== 'pichichis') {
            this.router.navigateByUrl('/team/' + itemId);
        }
    }
}
