import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Onboarding } from '@models/onboarding.model';
import {
    onboardingPlayer,
    onboardingReferee,
    onboardingTrainer,
    onboardingCheerleader
} from './onboardings.conf';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
})
export class OnboardingComponent implements OnInit {
    public onboarding: Onboarding;
    constructor(private router: Router) {}

    ngOnInit(): void {
        localStorage.setItem('ferro-onboarding', '1');
        this.setOnboardingDependsOnProfile();
    }

    setOnboardingDependsOnProfile(): void {
        const userProfile: string = localStorage.getItem('user-profile');
        switch (userProfile) {
            case 'player':
                this.onboarding = onboardingPlayer;
                break;
            case 'trainer':
                this.onboarding = onboardingTrainer;
                break;
            case 'referee':
                this.onboarding = onboardingReferee;
                break;
            case 'cheerleader':
                this.onboarding = onboardingCheerleader;
                break;
            default:
                break;
        }
    }

    manageOnboardingActions(): void {
        // if is last button -> go to city (team page)
        if (
            this.onboarding.currentStepIndex ===
            this.onboarding.steps.length - 1
        ) {
            this.onboarding.currentStepIndex = 0;
            const userProfile: string = localStorage.getItem('user-profile');
            switch (userProfile) {
                case 'trainer':
                    this.router.navigate(['/trainer']);
                    break;
                default:
                    this.router.navigate(['/team']);
                    break;
            }
        } else {
            this.onboarding.next();
        }
    }
}
