import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { OptionInteface } from '@models/option.model';
import { rankingItem, ResponseAPI_Team, ResponseAPI_User } from '@models/ranking.model';

import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';

@Injectable({
    providedIn: 'root'
})
export class RankingService extends HeadersService {
    constructor(public http: HttpClient) {
        super(http);
    }

    getRanking(month: number = null, division: number = null, year: number = null) {
        let data = {};
        if (division) {
            data['division'] = division;
        }
        if (month) {
            data['month'] = month;
        }
        if (year) {
            data['year'] = year;
        }

        const url = getCall('rankTeams', null, data);

        return this.http.get(url, this.getHeaders()).pipe(
            map((response: ResponseAPI_Team) => {
                let teamRank: rankingItem[] = response.results.map(team => {
                    return {
                        id: team.team.id,
                        name: team.team.name,
                        picture: team.team.picture,
                        score: team.total_goals,
                        updated_at: team.updated_at
                    };
                });
                return teamRank;
            })
        );
    }

    getTopScorers() {
        const url = getCall('rankUsers');

        return this.http.get(url, this.getHeaders()).pipe(
            map((response: ResponseAPI_User) => {
                let userRank: rankingItem[] = response.results.map(user => {
                    return {
                        id: user.id,
                        name: `${user.user.first_name} ${user.user.last_name}`,
                        picture: user.user.avatar,
                        score: user.total_goals,
                        updated_at: user.updated_at
                    };
                });
                return userRank;
            })
        );
    }

    getDivisions() {
        const url = getCall('division');

        return this.http.get(url, this.getHeaders()).pipe(
            map((response: any) => {
                let divisions: OptionInteface[] = response.map(div => {
                    return {
                        key: div.number,
                        value: div.name
                    };
                });
                return divisions;
            })
        );
    }
}
