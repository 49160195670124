import { FormHelper } from '@helpers/forms/forms';
import { PasswordRecoveryService } from '@services/api/passRecovery/passRecovery.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-password-recovery',
    templateUrl: './password-recovery.component.html',
    styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    public submitted: boolean = false;
    public subscriptions: { [key: string]: Subscription } = {};
    public token: string = '';
    public loading: boolean = false;
    public sending: boolean = false;
    public saved: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private _snackBar: MatSnackBar,
        private passRecoveryService: PasswordRecoveryService,
        private formHelper: FormHelper
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.getParams();
    }

    ngOnDestroy(): void {
        Object.values(this.subscriptions).forEach(subscription => subscription.unsubscribe());
    }

    get f() {
        return this.form.controls;
    }

    getParams(): void {
        this.subscriptions.getParams = this.route.paramMap.subscribe((params: ParamMap) => {
            const token = params.get('token') || null;
            if (token) {
                this.token = token;
                this.createPasswordForm();
            } else {
                this.createEmailForm();
            }
        });
    }

    createEmailForm(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
        this.loading = false;
    }

    createPasswordForm(): void {
        this.form = this.formBuilder.group(
            {
                password: ['', [Validators.required]],
                repeat: ['', [Validators.required]]
            },
            {
                validator: this.formHelper.mustMatchValidator('password', 'repeat')
            }
        );
        this.loading = false;
    }

    onSubmit(): void {
        if (this.sending) return;
        this.submitted = true;
        this.sending = true;
        if (this.form.valid) {
            if (this.token) {
                const { password } = this.form.getRawValue();
                this.passRecoveryService.postPassword(password, this.token).subscribe(
                    () => {
                        this.saved = true;
                        this.sending = false;
                    },
                    error => {
                        console.log(error);
                        const errorMessage =
                            error.error.key === 'TOKEN_NOT_VALID'
                                ? 'Parece que has accedido con un enlace no válido. Vuelve a solicitar el cambio de contraseña'
                                : 'Algo ha salido mal. Inténtalo más tarde';
                        this.sending = false;
                        this._snackBar.open(errorMessage, 'X', {
                            duration: 4000,
                            panelClass: ['error-snackbar']
                        });
                    }
                );
            } else {
                const { email } = this.form.getRawValue();
                this.passRecoveryService.postEmail(email).subscribe(
                    () => {
                        this.sending = false;
                        this._snackBar.open('Hemos enviado a tu email los pasos para resetear la contraseña', 'X', {
                            duration: 4000,
                            panelClass: ['success-snackbar']
                        });
                    },
                    error => {
                        console.log(error);
                        const errorMessage =
                            error.error.key === 'USER_NOT_EXISTS'
                                ? 'El email no existe en la base de datos'
                                : 'Algo ha salido mal. Inténtalo más tarde';
                        this.sending = false;
                        this._snackBar.open(errorMessage, 'X', {
                            duration: 4000,
                            panelClass: ['error-snackbar']
                        });
                    }
                );
            }
        } else {
            this.sending = false;
            this._snackBar.open('¡Hay errores en el formulario!', 'X', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
        }
    }

    onBackClick(): void {
        this.router.navigate(['/login']);
    }
}
