import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class FormHelper {
    constructor() {}

    /**
     * Retrieve the formGroup data in key: value object
     * @param form FormGroup
     * @param excludedFields name of fields that you don't want to retrieve
     */
    retrieveFormData(form: UntypedFormGroup, excludedFields: string[] = []): any {
        let data: any = {};

        for (let name in form.controls) {
            if (!excludedFields.includes(name)) {
                data[name] = form.controls[name].value;
            }
        }

        return data;
    }

    mustMatchValidator(controlName: string, matchingControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
    
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }
    
            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }
}
