<div class="bg futball-field h-100">
    <div class="container page-with-top-bar pb-md full-height">
        <div class="row full-height">
            <div class="col-12">
                <div class="white-modal opacity mb-md delimited-section-modal mt-md">
                    <div class="bordered-section">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="title d-flex align-items-center">
                                <img src="assets/images/icons/inbox.svg" class="icon-small mr-xs">
                                <span class="white-modal__title">Bandeja de entrada</span>
                            </div>
                            <div class="pointer">
                                <img src="assets/images/icons/close-icon.svg" [routerLink]="['/team']">
                            </div>
                        </div>
                        <div class="inbox-filter-bar">
                            Ver: <app-select (callback)="filterBy($event)" [isFilter]="true" [selectedKeyDefault]="filters['filter'] || filterOptions[0].key" [options]="filterOptions"></app-select>
                        </div>
                        <div class="more-filters mt-sm">
                            <div class="pointer mb-sm" (click)="toggleFilters()">
                                <img src="assets/images/icons/preferences.svg">
                                <span class="ml-xs mr-xs semi-bold">{{(showMoreFilters) ? 'Ocultar filtros': 'Mostrar filtros'}}</span>
                                <img src="assets/images/icons/chevron-down.svg" class="animate" [ngClass]="{'reverse': showMoreFilters}">
                            </div>
                            <div class="more-filters-wrapper" [ngClass]="{'d-none': !showMoreFilters}">
                                <div class="mb-xs" *ngIf="filterDelegation">
                                    <app-select
                                        (callback)="getSubFilter('delegation', $event.key)"
                                        [placeholder]="'Delegación'"
                                        [options]="filterDelegation"
                                        [selectedKeyDefault]="filters['delegation']">
                                    </app-select>
                                </div>
                                <div class="mb-xs" *ngIf="filterTeam">
                                    <app-select
                                        (callback)="getSubFilter('team', $event.key)"
                                        [placeholder]="'Equipos'"
                                        [options]="filterTeam"
                                        [selectedKeyDefault]="filters['team']">
                                    </app-select>
                                </div>
                                <div class="mb-xs" *ngIf="filterManagerPerson">
                                    <app-select
                                        (callback)="getSubFilter('user', $event.key)"
                                        [placeholder]="'Persona gestora'"
                                        [options]="filterManagerPerson"
                                        [selectedKeyDefault]="filters['user']">
                                    </app-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="incidents && TOTAL_INCIDENTS > MAX_INCIDENTS_PER_PAGE" class="incident-form-section delimited-section text-center">
                        <mat-paginator
                            #paginator
                            [length]="TOTAL_INCIDENTS"
                            [pageSize]="MAX_INCIDENTS_PER_PAGE"
                            [pageIndex]="currentPageNumber"
                            (page)="onPageChange($event)">
                        </mat-paginator>
                    </div>
                    <div class="two-cols-desktop" *ngIf="TOTAL_INCIDENTS > 0 && incidents">
                        <div class="bordered-section column d-flex justify-content-between align-items-center" *ngFor="let incident of incidents">
                            <div class="incident-block w-100 mt-sm mb-sm">
                                <div class="incident-block__header">
                                    <div class="white-panel w-100">
                                        <div class="incident-block__image" [class]="incident.status"></div>
                                        <p class="bold m-0 incident-name">{{incident.risk_incident?.name}}</p>
                                        <div class="d-flex justify-content-end w-100">
                                            <div class="d-flex align-items-center">
                                                <img src="assets/images/icons/calendario.svg" class="icon-small mr-xs">
                                                <span>{{incident.incident_date | date: 'dd/MM/yyyy'}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-sm">
                                        <app-progress-bar [userPoints]="incident.evaluation_questions_reported.reported" [nextLevelPonits]="incident.evaluation_questions_reported.total" [simple]="true"></app-progress-bar>
                                    </div>
                                    <div class="player-avatar-wrapper mt-sm">
                                        <div class="player-avatar mr-xs" [style.background-image]="'url('+incident.user.avatar+')'"></div>
                                        <div class="player-info">
                                            <p class="name semi-bold">{{incident.user.first_name}} {{incident.user.last_name}}</p>
                                            <p class="player-type">{{incident.team?.name || incident.user.team}}</p>
                                        </div>
                                    </div>
                                    <div class="identifier mt-xs">ID: {{incident.id_production_number}}</div>
                                    <div class="desc mt-sm">
                                        <p>{{incident.description}}</p>
                                    </div>
                                    <div class="incident-block__footer d-flex mt-sm">
                                        <div class="incident-status mr-xs">
                                            <div class="pb-ball"></div>
                                            
                                            <container-element [ngSwitch]="incident.status">
                                                <some-element *ngSwitchCase="'pending'"><span>pendiente</span></some-element>
                                                <some-element *ngSwitchCase="'in_review'"><span>en revisión</span></some-element>
                                                <some-element *ngSwitchCase="'finished'"><span>finalizado</span></some-element>
                                                <some-element *ngSwitchCase="'rejected'"><span>rechazado</span></some-element>
                                             </container-element>
                                        </div>
                                        <app-button [text]="'Ver detalles'" [hasRightArrow]="true" [rounded]="true" [routerLink]="['/report/'+incident.id]"></app-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="incidents && incidents.length === 0">
                        <div class="no-results mb-md mt-md">
                            <img src="assets/images/icons/binoculars.svg">
                            <span class="bold">Sin resultados</span>
                        </div>
                    </div>
                    <div class="incident-form-section delimited-section text-center">
                        <app-button [text]="'Volver'" [block]="true" [routerLink]="['/team']"></app-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

