import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { OptionInteface } from '@models/option.model';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
    @Input() placeholder: string;
    @Input() options: OptionInteface[];
    @Input() selectedItemDefault: OptionInteface;
    @Input() selectedKeyDefault: string;
    @Input() isFilter: boolean = false;
    @Input() searcher: boolean = true;
    @Input() icon: string;
    @Input() fromAPI: boolean = false;
    @Input() mode?: 'single' | 'multiple' = 'single';
    @Input() showValues?: boolean = false;
    @Input() multipleItemsDefault: OptionInteface[];
    @Input() maxSelectedOptions: number;
    @Input() maxSelectedOptionsText: string = 'máximo';
    @Input() disabled?: boolean = false;
    @Input() classOptions?: string = '';
    @Output() callback = new EventEmitter<any>();

    public opened: boolean = false;
    public selectedItem: OptionInteface;
    public optionsFiltered: OptionInteface[];
    public mine: boolean = false;
    public selectedRows: OptionInteface[] = [];
    public countRows: number = 0;
    public selectedValues: string = '';

    @ViewChild('inputSearcher') inputSearcher: ElementRef;

    constructor() {}

    ngOnInit(): void {
        this.optionsFiltered = this.options;
        if (this.selectedItemDefault) {
            this.selectedItem = this.selectedItemDefault;
        }
        if (this.selectedKeyDefault && !this.selectedItemDefault) {
            this.selectedItem = this.options.find((option) => option.key.toString() == this.selectedKeyDefault.toString());
        }
        if (this.multipleItemsDefault) {
            this.countRows =
                this.multipleItemsDefault.length === 0
                    ? 0
                    : this.multipleItemsDefault.length - 1;
            this.selectedRows = this.multipleItemsDefault;
            this.selectedItem = this.selectedRows[0];
            this.selectedValues = this.selectedRows
                .map((row) => row.value)
                .join(', ');
        }

        if (this.isFilter) this.searcher = false;
    }

    setExtraInfoMultipleOptions(
        options: OptionInteface[],
        selectedOptions: OptionInteface[]
    ): OptionInteface[] {
        selectedOptions = selectedOptions || [];
        return options.map((option) => {
            const isSelected = selectedOptions.includes(option);
            return {
                ...option,
                disabled: false,
                checked: isSelected,
            };
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedItem && !changes.selectedItem.isFirstChange()) {
            this.resetOption();
        }

        if (changes.options) {
            this.optionsFiltered = this.options;
        }
    }

    open(): void {
        if (!this.disabled) {
            this.opened = true;
            if (this.searcher) {
                this.inputSearcher.nativeElement.focus();
            }
        }
    }

    close(): void {
        this.opened = false;
        this.restoreOptions();
    }

    search(value: string): void {
        if (value === '') {
            this.restoreOptions();
        }

        this.options = this.optionsFiltered.filter((option) => {
            return option.value.toLowerCase().indexOf(value.toLowerCase()) >= 0;
        });
    }

    restoreOptions(): void {
        this.options = this.optionsFiltered;
        if (this.searcher) {
            this.inputSearcher.nativeElement.value = '';
        }
    }

    resetOption(): void {
        this.selectedItem = null;
    }

    selectOption(option: OptionInteface, parent?: string): void {
        if (this.mode === 'multiple' && parent !== 'checkbox') {
            return;
        } else if (this.mode === 'multiple') {
            this.handleMultipleMode(option);
        } else {
            this.handleSingleMode(option);
        }
    }

    handleMultipleMode(option: OptionInteface): void {
        if (!this.maxSelectedOptions || this.selectedRows.length <= this.maxSelectedOptions)  {
            const isSelected: boolean = this.selectedRows.some(row => row.key === option.key);
            this.handleCheckedOptionInterface(option, isSelected);
            this.selectedRows = isSelected
                ? this.selectedRows.filter(row => row.key !== option.key)
                : [...this.selectedRows, option];
            this.updateSelectedState();
            if (this.maxSelectedOptions) this.updateOptionDisabledState();
        }
    }

    updateOptionDisabledState(): void {
        const maxReached = this.maxSelectedOptions && this.selectedRows.length >= this.maxSelectedOptions;

        this.options.forEach(option => {
            option.disabled = maxReached && !option.checked;
        });
    }

    private handleCheckedOptionInterface(option: OptionInteface, isSelected: boolean): void {
        const index = this.options.indexOf(option);
        this.options[index].checked = !isSelected;
    }

    updateSelectedState(): void {
        this.selectedItem = this.selectedRows[0];
        this.countRows = this.selectedRows.length ? this.selectedRows.length - 1 : 0;
        const selectedKeys: Array<string | number> = this.selectedRows.map(row => row.key);
        selectedKeys.length ? this.callback.emit(selectedKeys) : this.callback.emit(null);
        this.selectedValues = this.selectedRows.map(row => row.value).join(', ');
    }
    
    handleSingleMode(option: OptionInteface): void {
        this.mine = option.key === 'me' && this.isFilter ? true : false;
        this.callback.emit(option);
        this.opened = false;
        this.selectedItem = option;
    
        if (option.key === '~clear~') {
            this.resetOption();
        }
    }

    isCheckedOption(option: OptionInteface): boolean {
        return this.multipleItemsDefault
            ? this.multipleItemsDefault.includes(option)
            : false;
    }
}
