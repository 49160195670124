import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class HeadersService {
    constructor(public _http: HttpClient) {}

    /**
     * Get Header Without Authorization
     * @returns
     */
    getHeaderWOA() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };
    }

    getHeaders() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem(
                    'ferro_access_token'
                )}`,
            }),
        };
    }

    /**
     * Returns httpOptions - headers without content-type
     */
    getHeaderWOCT(): any {
        return {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem(
                    'ferro_access_token'
                )}`,
            }),
        };
    }

    getHeaderBlob(): any {
        return {
            headers: new HttpHeaders({
                Authorization: `Bearer ${localStorage.getItem(
                    'ferro_access_token'
                )}`,
            }),
            responseType: 'blob',
        };
    }
}
