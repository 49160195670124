import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';

@Injectable({
    providedIn: 'root'
})
export class IncidentService extends HeadersService {
    constructor(public http: HttpClient) {
        super(http);
    }

    getIncidentsList(status: string = null, incidentId: number = null) {
        let params = status ? { filter: status } : {};
        const url = getCall('incident', incidentId, params);

        return this.http.get(url, this.getHeaders());
    }

    getIncidentListPage(url: string) {
        return this.http.get(url, this.getHeaders());
    }

    getRepository(filters: any = {}) {
        const url = getCall('incident', null, filters);

        return this.http.get(url, this.getHeaders());
    }

    getRepositoryPlayers(filters: any = {}) {
        const url = getCall('player', null, filters);

        return this.http.get(url, this.getHeaders());
    }

    getInboxTeams(filters: any = {}) {
        const url = getCall('teams', null, filters);

        return this.http.get(url, this.getHeaders());
    }

    getDelegations(filters: any = {}) {
        const url = getCall('delegation', null, filters);

        return this.http.get(url, this.getHeaders());
    }

    getRegionalDirection() {
        const url = getCall('regionalDirection', null, {});

        return this.http.get(url, this.getHeaders());
    }
}
