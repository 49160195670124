import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RadioButton } from '@models/option.model';

@Component({
    selector: 'app-radio-button-group',
    templateUrl: './radio-button-group.component.html',
    styleUrls: ['./radio-button-group.component.scss'],
})
export class RadioButtonGroupComponent implements OnInit {
    @Input() radioButtons: RadioButton[] = [];
    @Input() disabled?: boolean = false;
    @Output() callback = new EventEmitter<any>();
    constructor() {}

    ngOnInit(): void {}

    selectRB(rb: RadioButton): void {
        if (!this.disabled) {
            this.uncheckCurrent();
            rb.active = true;
            this.callback.emit(rb);
        }
    }

    uncheckCurrent(): void {
        this.radioButtons.find((rb) => rb.active).active = false;
    }
}
