import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { LoginService } from '@services/api/login/login.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(public router: Router, private loginService: LoginService) {}

    canActivate(route: ActivatedRouteSnapshot): Promise<boolean> | boolean {
        if (!localStorage.getItem('ferro_current_user')) {
            this.router.navigate(['login']);
        } else {
            const userProfile = this.loginService.currentAppUserValue.profile;
            const routeProfiles = route.data.profiles;
            if (!routeProfiles) return true;
            if (routeProfiles.includes(userProfile)) {
                return true;
            } else {
                this.router.navigate(['team']);
                return false;
            }
        }
    }
}
