export interface Notification {
    created_at: string;
    id: number;
    notification_library: NotificationLibrary;
    status: string;
    user: number;
    totalGoals?: number;
}
export interface NotificationLibrary {
    id: number;
    key: NotificationType;
    text?: string;
    email?: any;
    incident: any[];
    title_incident?: string;
    number_evaluations?: number;
    total_goals: number;
}

export enum NotificationType {
    GOAL = 'evaluation_incident',
    DRAFT = 'draft_incident',
}
