import { IncidentDetail } from '@models/incident.model';
import { AppUser } from '@models/user.model';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Helper {
    constructor() {}

    /**
     * Returns a image preview when is loaded using a
     * input file
     * @param files file loaded
     */
    getImagePreview(files: any) {
        const response = {
            preview: null,
            imagePath: null,
            error: false
        };

        return new Promise(resolve => {
            if (files.length === 0) {
                resolve(response);
            }

            const mimeType = files[0].type;
            if (mimeType.match(/image\/*/) == null) {
                response.error = true;
                resolve(response);
            }

            const reader = new FileReader();
            response.imagePath = files;
            reader.readAsDataURL(files[0]);
            reader.onload = _event => {
                response.preview = reader.result;
                resolve(response);
            };
        });
    }

    getTeamStars(divisionPosition: number): string[] {
        const result = ['grey', 'grey', 'grey'];

        if (typeof divisionPosition === 'number' && divisionPosition > 0) {
            const numStars = 4 - divisionPosition;
            for (let i = 0; i < numStars; i++) {
                result[i] = 'green';
            }
        }
        return result;
    }

    parseUserProfile(userProfile: string): string {
        let parsedProfile: string;
        switch (userProfile) {
            case 'player':
                parsedProfile = 'Jugador/a';
                break;
            case 'trainer':
                parsedProfile = 'Entrenador/a';
                break;
            case 'referee':
                parsedProfile = 'Árbitro/a';
                break;
            case 'cheerleader':
                parsedProfile = 'Animador/a';
                break;
            default:
                break;
        }
        return parsedProfile;
    }

    /**
     * Checks if the user can modify the given incident
     * @param user
     * @param incident
     * @returns `boolean`
     */
    canModifyIncident(user: AppUser, incident: IncidentDetail): boolean {
        return user.identifier === incident.user.identifier && incident.status === 'draft';
    }

    /**
     * Check if today is between two given dates
     * @param startDate 
     * @param endDate 
     * @returns `boolean`
     */
    isBetweenDates(startDate: string, endDate: string): boolean {
        const start = new Date(startDate) || '';
        const finish = new Date(endDate) || '';
        if (start && finish) {
            const today = new Date();
            return today.getTime() >= start.getTime() && today.getTime() <= finish.getTime();
        } else {
            return false;
        }
    }
}
