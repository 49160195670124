<div class="bg futball-field h-100">
    <div id="ranking-individual" class="container page-with-top-bar pb-md full-height">
        <div class="row">
            <div class="col">
                <div class="button-wrapper mt-3">
                    <div class="back-button">
                        <div class="arrow-back pointer" routerLink="/rankings"></div>
                        <p>Volver</p>
                    </div>
                    <app-select
                        class="select-filter"
                        *ngIf="type === 'total'" 
                        [placeholder]="'Filtrar por división'"
                        [options]="filterOptions"
                        (callback)="getSubFilter('division', $event.key)"
                    ></app-select>
                    <div class="monthly-filters" *ngIf="type === 'monthly'">
                        <div class="select-filter mb-xs">
                            <app-select
                            [placeholder]="'Filtrar por año'"
                            [selectedItemDefault]="filterYearOptions[YEAR_RANGE]"
                            [options]="filterYearOptions"
                            (callback)="getSubFilter('year', $event.key)"
                            ></app-select>
                        </div>
                        <div class="select-filter">
                            <app-select
                            [placeholder]="'Filtrar por mes'"
                            [selectedItemDefault]="filterMonthOptions[currentMonth+1]"
                            [options]="filterMonthOptions"
                            (callback)="getSubFilter('month', $event.key)"
                            ></app-select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <div class="row justify-content-center">
            <div class="col col-md-6 col-lg-5 col-xl-4">
    
                <div class="mt-3 ranking-title">
                    <div class="title-icon-wrapper">
                        <img class="title-icon"
                        [src]="iconUrl"
                        alt="">
                    </div>
                    <p>{{rankingName}}</p>
                </div>

                <div class="ranking-date mb-5">{{lastUpdate | date: 'dd/MM/yyyy HH:mm'}}</div>

                <div *ngFor="let item of ranking; index as i"
                     class="rank-item-wrapper"
                     [ngClass]=" { pointer: type !== 'pichichis' }"
                     routerLinkActive="router-link-active" 
                     (click)="clickOnItem(item.id)"
                >
                    <div class="item-position">{{i+1}}</div>
                    <div class="item-image-wrapper"
                         [style.background-image]="'url('+item.picture+')'"
                    ></div>
                    <div class="item-name">{{item.name}}</div>
                    <div class="item-score">{{item.score}}</div>
                </div>
            </div>
        </div>
    
        <div class="d-md-none fixed-bottom logo-wrapper mr-3">
            <img 
            class="logo"
            src="assets/images/logo-serveo.png" 
            alt="Serveo"
            >
        </div>
    </div>
</div>
        
