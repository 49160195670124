export interface onboardingStep {
    title: string;
    imageUrl: string;
    description: string;
    buttonText: string;
}
export class Onboarding {
    public steps: onboardingStep[];
    public currentStepIndex: number;

    constructor(steps: onboardingStep[], currentStepIndex: number = 0) {
        this.steps = steps;
        this.currentStepIndex = currentStepIndex;
    }

    next(): void {
        if (this.currentStepIndex + 1 < this.steps.length) {
            this.currentStepIndex += 1;
        }
    }

    previous(): void {
        if (this.currentStepIndex - 1 >= 0) {
            this.currentStepIndex -= 1;
        }
    }

    goTo(stepIndex: number) {
        if (stepIndex >= 0 && stepIndex < this.steps.length) {
            this.currentStepIndex = stepIndex;
        }
    }
}
