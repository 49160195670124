import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
    @Input() userPoints: number;
    @Input() nextLevelPonits: number;
    @Input() simple: boolean = false;

    constructor() {}

    ngOnInit(): void {
    }

    get percentValue() {
        return Math.round(
            (this.userPoints * 100) / this.nextLevelPonits
        );
    }

    get progressStyle() {
        return `width: ${this.percentValue}%`
    }
}
