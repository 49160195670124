import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Helper } from '@helpers/helper/helper';
import { AppUser } from '@models/user.model';
import { LoginService } from '@services/api/login/login.service';

@Component({
    selector: 'app-user-avatar',
    templateUrl: './user-avatar.component.html',
    styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent implements OnInit {
    @Output() callback = new EventEmitter<any>();

    public user: AppUser;
    public userProfileParsed: string;
    constructor(private loginService: LoginService, private helper: Helper) {}

    ngOnInit(): void {
        this.user = this.loginService.currentAppUserValue;
        if (this.user && this.user.profile) {
            this.userProfileParsed = this.helper.parseUserProfile(this.user.profile);
        }
    }

    toggleMenu(): void {
        this.callback.emit();
    }
}
