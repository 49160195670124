export class Evaluator {
    public expedient: number;
    public firstName: string;
    public lastName: string;
    public avatar: string;
    public fullName: string;

    constructor(
        expedient: number,
        firstName: string,
        lastName: string = '',
        avatar: string = ''
    ) {
        this.expedient = expedient;
        this.firstName = firstName;
        this.lastName = lastName;
        this.avatar = avatar;

        this.setFullName();
    }

    setFullName(): void {
        this.fullName = `${this.firstName} ${this.lastName}`;
    }
}
