import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Season, SeasonApiI } from '@models/season.model';
import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';

@Injectable({
    providedIn: 'root'
})
export class SeasonsService extends HeadersService {
    public seasonFinishDate: string = '';
    public seasonStartDate: string = '';

    constructor(public http: HttpClient) {
        super(http);
    }

    /** Returns finishDate and startDate from current season. If they are not
     * yet stored, calls backend to get them.
     */
    async getSeasonDates(): Promise<{ [key: string]: string}> {
        if (!this.seasonFinishDate || !this.seasonStartDate) {
            // call backend
            const { finishDate, startDate } = await this.getBackendSeason();
            if (finishDate && startDate) {
                this.setData(finishDate, startDate);
            }
        }
        return { finishDate: this.seasonFinishDate, startDate: this.seasonStartDate };
    }

    setData(finishDate: string, startDate: string): void {
        this.seasonStartDate = startDate;
        this.seasonFinishDate = finishDate;
    }

    getBackendSeason(): any {
        return new Promise((resolve, reject) => {
            const url = getCall('currentSeason', null, {});
            this.http.get(url, this.getHeaders()).subscribe(
                (response: SeasonApiI) => {
                    const season = new Season(response);
                    resolve({ finishDate: season.finishDate, startDate: season.startDate });
                },
                error => {
                    reject({});
                }
            );
        });
    }
}
