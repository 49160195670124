import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginService } from '@services/api/login/login.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private LoginService: LoginService, private router: Router) {}

    handleError(error: HttpErrorResponse) {
        if (error.status == 401 && this.router.url !== '/login') {
            this.LoginService.refreshToken().subscribe(
                () => {
                    location.reload();
                },
                error => {
                    console.log(error);
                    this.LoginService.logout();
                    this.router.navigate(['./login/']);
                }
            );
        }
        return throwError(error);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(this.handleError.bind(this)));
    }
}
