<div class="bg futball-field h-100">
    <div class="container page-with-top-bar pb-md full-height">
        <div class="row full-height">
            <div class="col-12">
                <div class="d-flex justify-content-center">
                    <div class="white-box mt-md d-flex justify-content-between align-items-center">
                        <img src="assets/images/icons/rank-monthly.svg" class="icon-normal" alt="">
                        <span class="ml-sm bold">Escoge uno de tus equipos</span>
                    </div>

                </div>
                <!-- TEAMS -->
                <div class="teams">
                    <div class="white-box team" *ngFor="let team of teams" [routerLink]="['/team/'+team.id]">
                        <div class="team-shield" [style.background-image]="'url('+team.picture+')'"></div>
                        <span class="semi-bold text-center">{{team.name}}</span>
                        <div class="stars-wrapper">
                            <div *ngFor="let starColor of team.teamStars">
                                <img [src]="'assets/images/icons/'+starColor+'-star.svg'">
                            </div>
                        </div>
                        <div class="goals-wrapper">
                            <div class="goals">
                                <div class="pb-ball"></div>
                                <span>{{team.total_goals}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>