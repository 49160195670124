<app-top-menu [isLogin]="true"></app-top-menu>
<div class="container pt-deskt-lg">
    <div class="row">
        <div class="col-12 col-md-6 city mb-md mt-sm">
            <app-city [level]="1"></app-city>
        </div>
        <div class="col-12 col-md-6 login-form mb-md">
            <form [formGroup]="loginForm" class="mbl-wrapper-strech">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" formControlName="email" name="email" placeholder="ejemplo@ejemplo.com">
                    <app-form-errors *ngIf="submitted" [errors]="f.email.errors"></app-form-errors>
                </div>

                <div class="form-group">
                    <label for="password">Contraseña</label>
                    <input type="password" formControlName="password" name="password">
                    <app-form-errors *ngIf="submitted" [errors]="f.password.errors"></app-form-errors>
                </div>

                <p *ngIf="loginError" class="text-center error">Credenciales incorrectas</p>
                <p *ngIf="userWithoutTeam"  class="text-center error">No formas parte de ningún equipo, ponte en contacto con el administrador de la plataforma</p>

                <div class="form-group button-row">
                    <app-button [text]="'Iniciar sesión'" (click)="onSubmit()"></app-button>
                </div>

                <div class="form-group">
                    <p class="text-center small">Si no recuerdas tu contraseña, puedes recuperarla haciendo click <a (click)="onRecoveryClick()">aquí</a>.</p>
                </div>

            </form>
        </div>
    </div>
</div>
