<div class="col-12" *ngIf="notification">
    <p *ngIf="notification.title" class="goals-noti-title text-center mt-sm">{{notification.title}}</p>
    <div class="d-flex justify-content-center">
        <div class="goal-counter" [ngClass]="{'mt-lg': notification.image}">
            <div *ngIf="notification.image" class="icon" [class]="notification.image"></div>
            <span>{{notification.subtitle}}</span>
        </div>
    </div>

    <div class="d-flex justify-content-center">
        <div class="white-modal opacity mt-lg mb-md">
            <div class="user-avatar" [style.background-image]="'url('+notification.modal.avatar+')'"></div>
            <div class="modal-title">
                <img *ngIf="notification.modal.title.icon" [src]="notification.modal.title.icon" class="icon-small mr-xs">
                <span class="bold">{{notification.modal.title?.text}}</span>
            </div>
            <div *ngIf="notification.modal.id" class="mb-sm mt-sm">
                {{notification.modal.id}}
            </div>
            <div class="mb-sm mt-sm">
                {{notification.modal.subtitle}}
            </div>
            <ng-content></ng-content>
        </div>
    </div>
    <div class="d-flex justify-content-center mb-md buttons-notification-wrapper">
        <app-button 
            *ngFor="let button of notification.buttons"
            [text]="button.text"
            [color]="button.color" 
            [textColor]="button.textColor"
            [rounded]="true" 
            [block]="true" 
            (click)="handleNextNotification(button?.route)"
        >
        </app-button>
    </div>
</div>