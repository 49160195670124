<div class="bg futball-field h-100">
    <div id="sports-minute-page" class="container page-with-top-bar pb-md full-height">
        <div class="row">
            <div class="col">
                <div class="button-wrapper mt-3">
                    <div class="arrow-back pointer" routerLink="/team"></div>
                    <p class="">Volver</p>
                </div>
            </div>   
        </div>
    
        <div class="row justify-content-center">
            <div class="col">

                <div class="mt-3 d-flex justify-content-center">
                    <div class="sports-minute-header">
                        <div class="header-title-container">
                            <img
                            class="header-reporters-icon"
                            src="assets/images/onboarding/reporteros.png" 
                            alt="Ranking">
                            <p class="header-title">Minuto deportivo
                                <span class="header-description d-none d-md-block">{{headerDescription}}</span>
                            </p>
                            <div class="header-live-icon d-md-none">LIVE</div>
                        </div>
                        <p class="header-description d-md-none">{{headerDescription}}</p>
                    </div>
                </div>

                <div 
                    class="sports-minute-content"
                    >
                    <div 
                        class="content-item pointer"
                        [ngClass]="{ isNew: !item.read }"
                        *ngFor="let item of newsList"
                        (click)="navigateToNews(item)"
                    >
                        <div class="item-date">{{item.created_at | date: ['dd/MM/yyyy']}}</div>
                        <div class="item-status">{{ item.read ? 'Visto' : '¡Nuevo!'}}</div>
                        <p class="item-title">{{item.title}}</p>
                    </div>
                </div>
                    
            </div>
            
        </div>
    
    </div>
</div>
        
