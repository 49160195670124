import { Component, OnInit } from '@angular/core';
import { incidentResume } from '@models/incidents-resume.model';
import { Notification, NotificationType } from '@models/notification.model';
import { AppUser } from '@models/user.model';
import { NotificationService } from '@services/api/notification/notification.service';
import { incidentsTemplate } from '@providers/store/incident-resume.store';
import { NotificationCard } from '@shared/notifications/notification-card/notification-card.model';
import { adaptNotificationToCard } from './notification.mapper';
import { Router } from '@angular/router';

@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
    public appUser: AppUser = JSON.parse(localStorage.getItem('ferro_app_user'));
    public incidents: incidentResume[];
    public notifications: Notification[];
    public currentNotification: Notification;
    public currentType: NotificationType;
    public notificationIndex: number = 0;
    public showNotifications: boolean = false;
    public notificationCard: NotificationCard;

    public notificationType = NotificationType;

    constructor(
        private notificationService: NotificationService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.incidents = incidentsTemplate.map(incident => {
            return { ...incident };
        });
        this.getNotifications();
    }

    getNotifications(): void {
        this.notificationService.getNotifications().subscribe((response: any) => {
            this.notifications = response.results.filter(
                (noti) =>
                    noti.notification_library.key ===
                        NotificationType.DRAFT ||
                    noti.notification_library.key === NotificationType.GOAL
            );

            if (this.notifications.length > 0) {
                this.notifications = this.notifications.map(noti => {
                    if (noti.notification_library.key === NotificationType.GOAL){
                        return { ...noti, totalGoals: noti.notification_library.total_goals };
                    }
                    return {...noti}
                });

                this.showNotifications = true;
                this.loadCurrentNotification();
            }
        });
    }

    loadCurrentNotification(): void {
        this.currentNotification = this.notifications[this.notificationIndex];
        this.notificationCard = adaptNotificationToCard(this.currentNotification, this.appUser);
        this.currentType = this.currentNotification.notification_library.key;
        if (this.currentType === NotificationType.GOAL) {
            this.setIncidents();
        }
    }

    setIncidents(): void {
        this.incidents.map((incident, index) => {
            const currentIncident =  this.currentNotification.notification_library?.incident;
            if (currentIncident) {
                incident.playerScore = currentIncident[index]?.goals;
            }
        });
    }

    next(route: string | null): void {
        this.notificationIndex += 1;
        this.notificationService.setNotificationAsReaded(this.currentNotification.id).subscribe(
            () => {},
            error => {
                console.log(error);
            }
        );

        if (this.notificationIndex <= this.notifications.length - 1) {
            // next notification popup
            this.loadCurrentNotification();
        } else {
            // no more notifications
            this.showNotifications = false;
        }
        if (route) {
            const incidentId = this.currentNotification.notification_library.id;
            this.router.navigate([`/${route}/${incidentId}`])
        }
    }
}
