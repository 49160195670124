<div class="bg futball-field h-100" *ngIf="!loading">
    <div class="container page-with-top-bar pb-md full-height">
        <div class="row full-height">
            <div class="col-12" [ngClass]="{'d-flex-center': saved}">
                <div class="white-modal opacity mb-md delimited-section-modal mt-md" *ngIf="!saved">
                    <form [formGroup]="reportForm">
    
                        <div class="bordered-section d-flex justify-content-between align-items-center">
                            <div class="title d-flex align-items-center">
                                <img src="assets/images/icons/reportar.svg" class="icon-small mr-xs">
                                <span class="white-modal__title">Nuevo incidente</span>
                            </div>
                            <div class="pointer">
                                <img src="assets/images/icons/close-icon.svg" [routerLink]="['/team']">
                            </div>
                        </div>
                        <div class="two-cols-desktop">
                            <div class="incident-form-section bordered-section column">
                                <div class="incident-form-group mb-0">
                                    <div class="incident-question-label">
                                        <img src="assets/images/icons/calendario.svg" class="mr-xs">
                                        Datos del incidente
                                    </div>
                                    <div class="two-cols">
            
                                        <div class="column date form-group">
                                            <label for="date">Fecha *</label>
                                            <input matInput [matDatepicker]="datepicker" [matDatepickerFilter]="myDateFilter" [disabled]="incident" formControlName="incident_date" (click)="datepicker.open()" type="text" class="text-center" name="date" placeholder="00/00/00">
                                            <mat-datepicker #datepicker></mat-datepicker>
                                            <app-form-errors *ngIf="submitted" [errors]="f.incident_date.errors"></app-form-errors>
        
                                        </div>
                                        <div class="column time form-group">
                                            <label for="time">Hora *</label>
                                            <input [ngxTimepicker]="picker" [format]="24" formControlName="incident_hour" placeholder="00:00" class="text-center">
                                            <ngx-material-timepicker #picker></ngx-material-timepicker>
                                            <app-form-errors *ngIf="submitted" [errors]="f.incident_hour.errors"></app-form-errors>
                                        </div>
                                    </div>
                                    <div class="mb-sm">
                                        <label>Hora de la jornada *</label>
                                        <div class="incident-question form-group">
                                            <app-select 
                                            *ngIf="workDayHourOptions" 
                                            [placeholder]="'Primera hora'"
                                            [options]="workDayHourOptions"
                                            [selectedItemDefault]="incident ? getDefaultValue('work_hour') : null"
                                            (callback)="setSelectFormValue('work_day_hour', $event.key)"></app-select>
                                            <app-form-errors *ngIf="submitted" [errors]="f.work_day_hour.errors"></app-form-errors>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="location">Lugar *</label>
                                        <input type="text" name="location" formControlName="location" placeholder="Escriba un lugar">
                                        <app-form-errors *ngIf="submitted" [errors]="f.location.errors"></app-form-errors>
                                    </div>
                                    <div class="form-group">
                                        <label for="description">Descripción *</label>
                                        <textarea name="description" formControlName="description" placeholder="Escriba los detalles del incidente"></textarea>
                                        <app-form-errors *ngIf="submitted" [errors]="f.description.errors"></app-form-errors>
                                        <div class="inputfile file-with-textarea">
                                            Cargar archivos
                                            <input type="file" name="loadFiles" multiple (change)="onFilesSelected($event.target.files, 'description')">
                                        </div>
                                        <app-form-errors *ngIf="submitted" [errors]="f.description_files.errors"></app-form-errors>
                                        <div class="file-box mt-sm">
                                            <div class="file-chip" *ngFor="let file of descriptionFiles">
                                                {{file.name}}
                                                <span class="remove-file pointer bold" (click)="removeFile(file, 'description')">x</span>
                                            </div>
                                            <div class="files-wrapper">
                                                <div *ngFor="let file of existingDescriptionFiles; let i = index">
                                                    <div class="file-box">
                                                        <a [href]="file.image" download target="_blank">
                                                            <div class="file-preview cursor" [ngClass]="{file: !file.isImg}" [style.background-image]="'url('+file.image+')'"></div>
                                                        </a>
                                                        <i class="material-icons notranslate" (click)="removeExistingFile(i, 'description')">highlight_off</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="small" *ngIf="existingDescriptionFiles.length === 0">Carga aquí las fotografías</p>
                                    </div>
                                </div>
                                <div class="incident-form-group">
                                    <div class="incident-question-label">
                                        <img src="assets/images/icons/briefcase.svg" class="mr-xs">
                                        Contrato / Centro *
                                    </div>
                                    <div class="incident-question form-group">
                                        <app-select
                                        *ngIf="contractOptions" 
                                        [placeholder]="'Escoja un contrato'"
                                        [options]="contractOptions"
                                        [selectedItemDefault]="incident ? getDefaultValue('contract') : null"
                                        (callback)="setSelectFormValue('contract', $event.key)"></app-select>
                                        <app-form-errors *ngIf="submitted" [errors]="f.contract.errors"></app-form-errors>
                                    </div>
                                </div>
                                <div class="incident-form-group">
                                    <div class="incident-question-label">
                                        <img src="assets/images/icons/mismos-contratos.svg" class="mr-xs">
                                        Actividad *
                                    </div>
                                    <div class="incident-question form-group">
                                        <app-select 
                                        *ngIf="activityListOptions" 
                                        [placeholder]="'Escoja una actividad'"
                                        [options]="activityListOptions"
                                        [selectedItemDefault]="incident ? getDefaultValue('activity_incident') : null"
                                        (callback)="setSelectFormValue('activity_incident', $event.key)"></app-select>
                                        <app-form-errors *ngIf="submitted" [errors]="f.activity_incident.errors"></app-form-errors>
                                    </div>
                                </div>
                            </div>
                            <div class="incident-form-section bordered-section column">
                                <div class="incident-form-group">
                                    <div class="incident-question-label">
                                        <img src="assets/images/icons/flag.svg" class="mr-xs">
                                        Riesgo potencial *
                                    </div>
                                    <div class="incident-question form-group">
                                        <app-select 
                                        *ngIf="riskIncidentsOptions"
                                        [placeholder]="'Elige uno de los riesgos'"
                                        [options]="riskIncidentsOptions"
                                        [selectedItemDefault]="incident ? getDefaultValue('risk_incident') : null"
                                        (callback)="setSelectFormValue('risk_incident', $event.key)"></app-select>
                                        <app-form-errors *ngIf="submitted" [errors]="f.risk_incident.errors"></app-form-errors>
                                    </div>
                                </div>
                                <div class="incident-form-group">
                                    <div class="incident-question-label">
                                        <img src="assets/images/icons/causas-inmediatas.svg" class="mr-xs">
                                        Causas inmediatas *
                                    </div>
                                    <div class="incident-question form-group">
                                        <app-select 
                                        *ngIf="inmediateCausesOptions" 
                                        [placeholder]="'Escoja al menos una causa inmediata'"
                                        [options]="inmediateCausesOptions"
                                        [mode]="'multiple'"
                                        [showValues]="true"
                                        [maxSelectedOptions]="maxAllowedCauseOption"
                                        [multipleItemsDefault]="incident ? getMultipleDefaultValue('inmediate_causes') : null"
                                        (callback)="setSelectFormValue('inmediate_causes', $event)"></app-select>
                                        <app-form-errors *ngIf="submitted" [errors]="f.inmediate_causes.errors"></app-form-errors>
                                    </div>
                                </div>
                                <div class="incident-form-group">
                                    <div class="incident-question-label">
                                        <img src="assets/images/icons/causas-raiz.svg" class="mr-xs">
                                        Causas raíz *
                                    </div>
                                    <div class="incident-question form-group">
                                        <app-select 
                                        *ngIf="rootCausesOptions" 
                                        [placeholder]="'Escoja al menos una causa raíz'"
                                        [options]="rootCausesOptions"
                                        [mode]="'multiple'"
                                        [showValues]="true"
                                        [maxSelectedOptions]="maxAllowedCauseOption"
                                        [multipleItemsDefault]="incident ? getMultipleDefaultValue('root_causes') : null"
                                        (callback)="setSelectFormValue('root_causes', $event)"></app-select>
                                        <app-form-errors *ngIf="submitted" [errors]="f.root_causes.errors"></app-form-errors>
                                    </div>
                                </div>
                                <div class="incident-form-group">
                                    <div class="incident-question-label">
                                        <img src="assets/images/icons/acciones-preventivas.svg" class="icon-normal mr-xs">
                                        Acciones preventivas / correctivas *
                                    </div>
                                    <div class="incident-question form-group">
                                        <textarea name="preventive_action" formControlName="preventive_action" placeholder="Escriba las acciones preventivas y/o correctivas"></textarea>
                                        <app-form-errors *ngIf="submitted" [errors]="f.preventive_action.errors"></app-form-errors>
                                        <div class="inputfile file-with-textarea">
                                            Cargar archivos
                                            <input type="file" name="loadFiles" multiple (change)="onFilesSelected($event.target.files, 'actions')">
                                        </div>
                                        <app-form-errors *ngIf="submitted" [errors]="f.preventive_actions_files.errors"></app-form-errors>
                                        <div class="file-box mt-sm">
                                            <div class="file-chip" *ngFor="let file of actionsFiles">
                                                {{file.name}}
                                                <span class="remove-file pointer bold" (click)="removeFile(file, 'actions')">x</span>
                                            </div>
                                            <div class="files-wrapper">
                                                <div *ngFor="let file of existingActionsFiles; let i = index">
                                                    <div class="file-box">
                                                        <a [href]="file.image" download target="_blank">
                                                            <div class="file-preview cursor" [ngClass]="{file: !file.isImg}" [style.background-image]="'url('+file.image+')'"></div>
                                                        </a>
                                                        <i class="material-icons notranslate" (click)="removeExistingFile(i, 'actions')">highlight_off</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="small" *ngIf="existingActionsFiles.length === 0">Carga aquí las evidencias de cierre de las acciones</p>
                                    </div>
                                </div>
                                <div class="incident-form-group">
                                    <div class="incident-question-label">
                                        <img src="assets/images/icons/hojas.svg" class="mr-xs">
                                        Reunión de inicio y meta *
                                    </div>
                                    <div class="form-group">
                                        <div class="inputfile">
                                            Cargar archivos
                                            <input type="file" name="loadFiles" multiple (change)="onFilesSelected($event.target.files, 'investigation')">
                                        </div>
                                        
                                        <app-form-errors *ngIf="submitted" [errors]="f.investigation_files.errors"></app-form-errors>
    
                                        <div class="file-box mt-sm">
                                            <div class="file-chip" *ngFor="let file of reportFiles">
                                                {{file.name}}
                                                <span class="remove-file pointer bold" (click)="removeFile(file, 'investigation')">x</span>
                                            </div>
                                            <div class="files-wrapper">
                                                <div *ngFor="let file of existingReportFiles; let i = index">
                                                    <div class="file-box">
                                                        <a [href]="file.image" download target="_blank">
                                                            <div class="file-preview cursor" [ngClass]="{file: !file.isImg}" [style.background-image]="'url('+file.image+')'"></div>
                                                        </a>
                                                        <i class="material-icons notranslate" (click)="removeExistingFile(i, 'investigation')">highlight_off</i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p class="small" *ngIf="reportFiles.length === 0">Adjunta aquí el registro de la reunión de inicio y meta donde se haya tratado el incidente.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="incident-form-section delimited-section">
                            <div class="incident-form-group mb-0">
                                <div class="required-wrapper">
                                    <span class="required-wrapper__label">
                                        * Los campos marcados son obligatorios
                                    </span>
                                </div>
                            </div>
                        </div>
    
                        
        
                        <div class="incident-form-section delimited-section text-center">
                            <app-button
                            *ngIf="!sending"
                            class="send-button"
                            [text]="'Enviar'"
                            [block]="true"
                            [icon]="'reportar'"
                            [disabled]="!this.reportForm.valid"
                            (click)="onSubmit(false)"
                            ></app-button>

                            <app-button
                            *ngIf="!sending"
                            [text]="'Guardar en borradores'"
                            [block]="true"
                            [disabled]="this.reportForm.valid
                                || (this.reportForm.controls.description?.invalid
                                || this.reportForm.controls.incident_date?.invalid
                                || this.reportForm.controls.contract?.invalid)"
                            (click)="onSubmit(true)"
                            ></app-button>
                        </div>
                    </form>
    
                </div>
    
    
                <!-- SUCCESS SAVED -->
                <div class="white-modal report-success mt-lg" *ngIf="saved">
                    <div class="user-avatar big" [style.background-image]="'url('+user.avatar+')'"></div>
                    <div class="white-modal__title text-center mb-md">
                        <img src="assets/images/icons/reportar.svg">
                        {{ isDraft ? '¡Borrador guardado!' : '¡Incidente enviado!' }}
                    </div>
                    <div class="white-modal__body">
                        <p class="text-center mb-md">
                            {{ isDraft
                                ? 'Recuerda que tienes 15 días desde la primera vez que guardaste el borrador para poder enviarlo. Podrás encontrar todos tus borradores en "Repositorio" > Ver: "Míos" > "Borradores"'
                                : 'El equipo de arbitraje está analizando tu jugada. Pronto descubrirás cuántos goles has marcado.'
                            }}
                        </p>
                        <div class="text-center">
                            <app-button [text]="'Continuar'" [block]="true" [routerLink]="['/team']"></app-button>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
    </div>
</div>