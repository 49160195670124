import { OptionInteface } from './option.model';

export interface EvaluationAnswerPoints {
    name: string;
    punctuation: number;
}
export interface EvaluationQuestion {
    id: number;
    name: string;
    icon: string;
    max_punctuation: number;
    section: string;
    evaluation_finished: any[];
    is_boolean: boolean;
    answer_points: EvaluationAnswerPoints[]
}

/**
 * Evaluation Question Parsed
 *
 */
export interface EvaluationQP {
    id: number;
    name: string;
    options: OptionInteface[];
    evaluation_finished: any;
    icon: string;
}

export interface EvaluationSection {
    name: string;
    questions: EvaluationQP[];
}

export class EvaluationForm {
    public sections: EvaluationSection[];
    constructor(questions: EvaluationQuestion[]) {
        this.sortQuestions(questions);
    }

    sortQuestions(questions: EvaluationQuestion[]): void {
        let sectionNames: string[] = [];
        questions.map((q) => {
            if (!sectionNames.includes(q.section)) {
                sectionNames.push(q.section);
            }
        });
        this.sections = sectionNames.map((sn) => {
            let sectionQuestions = questions.filter(
                (q: EvaluationQuestion) => q.section === sn
            );
            return {
                name: sn,
                questions: sectionQuestions.map((sq: EvaluationQuestion) => {
                    return {
                        id: sq.id,
                        name: sq.name,
                        options: this.makeRangeOptions(sq.max_punctuation, sq.is_boolean, sq.answer_points),
                        icon: sq.icon,
                        evaluation_finished:
                            sq.evaluation_finished.length > 0
                                ? sq.evaluation_finished[0]
                                : null,
                    };
                }),
            };
        });
    }

    makeRangeOptions(maxNumber: number, isBoolean: boolean, answerPoints: EvaluationAnswerPoints[]): OptionInteface[] {
        let options: OptionInteface[] = [{ key: '-', value: '-' }];
        if (isBoolean && maxNumber === 1) {
            options.push(
                { key: 0, value: 'No'},
                { key: 1, value: 'Sí'}
            );
        } else {
            // some questions have setted its points and answers,
            // and we don't need to do a for with the maxNumber
            if (answerPoints.length) {
                answerPoints.forEach(item => {
                    const key = item.punctuation <= maxNumber ? item.punctuation : 0;
                    options.push(
                        { key, value: item.name }
                    );
                });
            } else {
                for (let index = 0; index <= maxNumber; index++) {
                    options.push({
                        key: index,
                        value: index.toString(),
                    });
                }
            }
        }
        return options;
    }
}

export interface EvaluatedQuestion {
    id: number;
    punctuation: number;
}

export interface EvaluationApiPOST {
    incident: number;
    is_valid: boolean;
    is_draft: boolean;
    evaluation_questions: EvaluatedQuestion[];
    comments: string | null;
    risk?: number | string;
}