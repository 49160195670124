import { Notification, NotificationType } from '@models/notification.model';
import { ButtonNotificationCard, NotificationCard } from '@shared/notifications/notification-card/notification-card.model';
import { AppUser } from '@models/user.model';

const defaultContinueButton: ButtonNotificationCard = {
    text: 'Continuar',
    color: 'accent',
    textColor: 'white',
    route: null
}

export function adaptNotificationToCard(
    notification: Notification,
    appUser?: AppUser
): NotificationCard {
    const currentNotiType = notification.notification_library.key;

    switch (currentNotiType) {
        case NotificationType.DRAFT:
            return {
                title: '¡Tu incidencia ha sido enviada a ...!',
                subtitle: 'Borrador',
                modal: {
                    notificationId: '',
                    title: {
                        text: `Incidencia en borrador`,
                        icon: 'assets/images/icons/information.svg',
                    },
                    subtitle: `El equipo de arbitraje ha validado tu incidente con identificador ${notification.notification_library.id} y lo ha enviado a borradores.`,
                    avatar: appUser?.avatar,
                },
                buttons: [
                    {
                        ...defaultContinueButton,
                        textColor: 'dark',
                        color: 'white',
                    },
                    {
                        text: 'Ver incidente',
                        textColor: 'white',
                        color: 'accent',
                        route: 'report',
                    },
                ],
            };

        case NotificationType.GOAL:
            return {
                title: '¡Has marcado...!',
                subtitle: notification.totalGoals + ' goles',
                image: 'pb-ball',
                modal: {
                    title: {
                        text: notification.notification_library.title_incident,
                        icon: 'assets/images/icons/reportar.svg',
                    },
                    subtitle: 'ID:' + notification.notification_library.id,
                    avatar: appUser?.avatar,
                },
                buttons: [defaultContinueButton],
            };
    }
}
