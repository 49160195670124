<div class="bg futball-field-ball h-100">
    <div id="faq-page" class="container page-with-top-bar pb-md full-height">
        <div class="row full-height">
            <div class="col">
                <div class="white-modal opacity mb-md mt-md">
                    <div class="faq-container bordered-section">
                        <div class="pointer d-flex justify-content-end">
                            <img src="assets/images/icons/close-icon.svg" [routerLink]="['/team']">
                        </div>
                        <div class="faq-title mt-sm d-flex justify-content-start align-items-center">
                            <div class="title-image-container d-flex-center">
                                <img src="assets/images/icons/information.svg">
                            </div>
                            <span class="faq-title-text">Preguntas Frecuentes</span>
                        </div>
                        <div class="separator mt-sm"></div>
                        
                        <div
                            *ngFor="let faq of faqs"
                            class="faq-category pt-sm"
                        >
                            <div class="faq-category-title d-flex justify-content-start align-items-center">
                                <div class="faq-image-container d-flex-center">
                                    <img src="assets/images/icons/information.svg">
                                </div>
                                <span class="faq-category-text">{{faq.title}}</span>
                            </div>

                            <div 
                                *ngFor="let question of faq.questions"
                                class="faq-category-question-container mt-md"
                            >
                                <div class="question-title d-flex justify-content-between align-items-center">
                                    <span class="question-title-text">{{question.title}}</span>
                                    <div 
                                        class="question-expand-icon pointer d-flex-center"
                                        [ngClass]="{ closed: question.closed }"
                                        (click)="question.closed = !question.closed"
                                    >
                                        <img src="assets/images/icons/chevron-down.svg" alt="Expandir/Contraer">
                                    </div>
                                </div>
                                <div 
                                    class="faq-category-question-answer"
                                    [ngClass]="{ closed: question.closed }"
                                    [innerHtml]="question.answer"
                                >
                                </div>
                            </div>

                            <div class="separator mt-sm"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
                

