import { SeasonsService } from '@services/api/seasons/seasons.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '@helpers/helper/helper';
import { Evaluator } from '@models/evaluators.model';
import { IncidentDetail } from '@models/incident.model';
import { AppUser } from '@models/user.model';
import { IncidentService } from '@services/api/incidents/incidents.service';
import { LoginService } from '@services/api/login/login.service';

@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
    public status = 2; // 0 = pending; 1 = pendiente comité (Evaluado pero no del todo); 2 = completado/validado
    public evaluators: Evaluator[] = [];
    public reportId: number;
    public incident: IncidentDetail;
    public imageFileExtensions: string[] = ['jpeg', 'jpg', 'png'];
    public punctuation: any[] = [];
    public user: AppUser;
    public incidentByUser: boolean = false;
    public seasonStartDate: string = '';
    public seasonFinishDate: string = '';
    public showDraftButton: boolean = false;
    public showEvaluateButton: boolean = false;
    public showComments: boolean = false;
    public commentSectionAllowedUsers = ['referee', 'trainer', 'cheerleader'];

    constructor(
        private route: ActivatedRoute,
        private indicentService: IncidentService,
        private router: Router,
        private loginService: LoginService,
        private helper: Helper,
        private seasonService: SeasonsService
    ) {}

    get statusText(): string {
        let result = '';
        switch (this.status) {
            case 0:
                result = 'Pendiente de revisión';
                break;
            case 2:
                result = this.incident.is_valid ? 'Validado' : 'No Válido';
                break;
            case 3:
                result = 'No Válido';
                break;
        }
        return result;
    }

    get userProfile(): string {
        return this.user.profile;
    }

    ngOnInit(): void {
        this.reportId = this.route.snapshot.params.report_id;
        this.user = this.loginService.currentAppUserValue;
        this.loadIncident();
    }

    handleCommentVisibility(incident: IncidentDetail, user: AppUser): void {
        const userProfile = localStorage.getItem('user-profile');
        this.showComments = this.commentSectionAllowedUsers.includes(userProfile)
            || (userProfile === 'player' && user.identifier === incident.user.identifier);
    }

    loadIncident(): void {
        this.indicentService.getIncidentsList(null, this.reportId).subscribe(
            (response: IncidentDetail) => {
                this.evaluators = response.all_evaluation_referees.map(user => {
                    return new Evaluator(user.n_exp, user.first_name, user.last_name, user.avatar);
                });
                this.incident = response;
                this.incidentByUser = this.helper.canModifyIncident(this.user, this.incident);
                this.status = this.parseIncidentStatus(this.incident.status);
                this.parseFiles();

                const icons = [
                    'situacion-riesgo',
                    'entrevista',
                    'explicacion',
                    'graficos',
                    'formato-investigacion',
                    'correcta-identificacion',
                    'evidencia-cierre',
                    'otros-contratos',
                    'bombilla'
                ];
                this.punctuation = response.all_points_evaluated_questions.map((q, index) => {
                    return {
                        icon: icons[index],
                        question: q.question,
                        points: q.points,
                        goals: response.all_goals_evaluated_questions ? response.all_goals_evaluated_questions : null
                    };
                });
                this.getSeasonFinishDate();
                this.handleCommentVisibility(this.incident, this.user);
            },
            error => {
                console.log(error);
            }
        );
    }

    parseFiles(): void {
        this.incident.investigation_files = this.incident.investigation_files.map(file => {
            const regex = /(?:\.([^.]+))?$/;
            const ext = regex.exec(file.incident_file.split('?')[0])[1];
            return {
                id: file.id,
                incident_file: file.incident_file,
                isImg: this.imageFileExtensions.includes(ext)
            };
        });
        this.incident.preventive_actions_files = this.incident.preventive_actions_files.map(file => {
            const regex = /(?:\.([^.]+))?$/;
            const ext = regex.exec(file.incident_file.split('?')[0])[1];
            return {
                id: file.id,
                incident_file: file.incident_file,
                isImg: this.imageFileExtensions.includes(ext)
            };
        });
        this.incident.description_files = this.incident.description_files.map(file => {
            const regex = /(?:\.([^.]+))?$/;
            const ext = regex.exec(file.incident_file.split('?')[0])[1];
            return {
                id: file.id,
                incident_file: file.incident_file,
                isImg: this.imageFileExtensions.includes(ext)
            };
        });
    }

    parseIncidentStatus(status: string): number {
        let nStatus: number;
        switch (status) {
            case 'pending':
                nStatus = 0;
                break;
            case 'in_review':
                nStatus = 1;
                break;
            case 'finished':
                nStatus = 2;
                break;
            case 'rejected':
                nStatus = 3;
                break;
            case 'draft':
                nStatus = 4;
                break;
            default:
                break;
        }

        return nStatus;
    }

    async getSeasonFinishDate(): Promise<void> {
        const { finishDate, startDate } = await this.seasonService.getSeasonDates();
        this.seasonFinishDate = finishDate;
        this.seasonStartDate = startDate;
        this.checkDraftButton();
        this.checkEvaluateButton();
    }

    checkDraftButton(): void {
        if (this.seasonFinishDate && this.seasonStartDate) {
            // if given date is older than today player can report incident
            this.showDraftButton =
                this.incidentByUser &&
                this.status === 4 &&
                this.user.profile === 'player' &&
                this.helper.isBetweenDates(this.seasonStartDate, this.seasonFinishDate);
        } else {
            this.showDraftButton = false;
        }
    }

    checkEvaluateButton(): void {
        if (this.seasonFinishDate && this.seasonStartDate) {
            this.showEvaluateButton =
                this.userProfile === 'referee' &&
                this.user.regional_directions.some(regDir => regDir.id === this.incident.regional_direction.id) &&
                (this.status === 0 || this.status === 1) &&
                this.helper.isBetweenDates(this.seasonStartDate, this.seasonFinishDate);
        } else {
            this.showEvaluateButton = false;
        }
    }

    goBack(): void {
        const backPage: string = sessionStorage.getItem('ferro-page');
        switch (backPage) {
            case 'inbox':
                this.router.navigate(['/inbox']);
                break;
            case 'repository':
                this.router.navigate(['/repository']);
                break;
            default:
                this.router.navigate(['/team']);
                break;
        }
    }

    toDraft(reportId: number): void {
        this.router.navigate([`new-report/${reportId}`]);
    }
}
