<div class="container-pb" 
[ngClass]="{
    simple: simple, 
    completed: (userPoints === nextLevelPonits),
    'simple-1': percentValue <= 34,
    'simple-2': percentValue > 34 && percentValue <= 67,
    'simple-3': percentValue > 67 && percentValue < 99
}">
    <div class="pb-ball" *ngIf="!simple"></div>
    <div class="progress">
        <div class="progress-bar" role="progressbar" [style]="progressStyle" 
        attr.aria-valuenow="{{ percentValue }}" aria-valuemin="0" aria-valuemax="100"></div>
    </div>
    <div class="score-pb" *ngIf="!simple">{{ userPoints }}/{{ nextLevelPonits }}</div>
</div>




