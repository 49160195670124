import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
    @Input() text: string;
    @Input() icon?: string;
    @Input() disabled?: boolean = false;
    @Input() color?: 'accent' | 'white' | 'grey' = 'accent';
    @Input() textColor?: 'white' | 'dark' = 'white';
    @Input() isNext?: boolean = false; // If true, button only has >  icon
    @Input() hasRightArrow?: boolean = false;
    @Input() block: boolean = false;
    @Input() rounded: boolean = false;

    constructor() {}
    
    get buttonClass(): string {
        return this.generateClasses();
    }

    ngOnInit(): void {
        if (this.icon) {
            this.icon = this.generateIconUrl();
        }
    }

    generateClasses(): string {
        let iconClass: string = this.icon ? 'hasIcon' : '';
        let blockClass: string = this.block ? 'block' : '';
        let roundedClass: string = this.rounded ? 'round' : '';
        let colorClass: string = this.disabled ? 'disabled' : this.color;

        return `${colorClass} text-${this.textColor} ${iconClass} ${blockClass} ${roundedClass}`;
    }

    generateIconUrl(): string {
        return `assets/images/icons/${this.icon}.svg`;
    }
}
