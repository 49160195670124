import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiUserInterface, AppUser } from '@models/user.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService extends HeadersService {
    public userSubject: BehaviorSubject<ApiUserInterface>;
    public user: Observable<ApiUserInterface>;
    public appUserSubject: BehaviorSubject<AppUser>;
    public appUser: Observable<AppUser>;

    constructor(public http: HttpClient, private router: Router) {
        super(http);
        this.userSubject = new BehaviorSubject<ApiUserInterface>(
            JSON.parse(localStorage.getItem('ferro_api_user'))
            // REVIEW: No hay ningún setItem('ferro_api_user')
        );
        this.user = this.userSubject.asObservable();
        this.appUserSubject = new BehaviorSubject<AppUser>(JSON.parse(localStorage.getItem('ferro_app_user')));
        this.appUser = this.appUserSubject.asObservable();
    }

    public get currentAppUserValue(): AppUser {
        return this.appUserSubject.value;
    }

    login(username: string, password: string) {
        const url = getCall('login', null);
        const httpData = new URLSearchParams();
        httpData.set('client_id', environment.client_id);
        httpData.set('client_secret', environment.client_secret);
        httpData.set('grant_type', 'password');
        httpData.set('username', username);
        httpData.set('password', password);

        return this._http.post<any>(url, httpData.toString(), this.getHeaderWOA()).pipe(
            map(user => {
                // store user details
                localStorage.setItem('ferro_current_user', JSON.stringify(user));
                this.userSubject.next(user);
                localStorage.setItem('ferro_access_token', user.access_token);
                return user;
            })
        );
    }

    getUser() {
        const url = getCall('user', null);
        return this._http.get(url, this.getHeaders()).pipe(
            map((user: AppUser) => {
                localStorage.setItem('ferro_app_user', JSON.stringify(user));
                this.appUserSubject.next(user);
                return user;
            })
        );
    }

    updateUserAvatar(userId: string, data: FormData) {
        const url = getCall('user', userId);
        return this._http.put(url, data, this.getHeaderWOCT()).pipe(
            map(user => {
                return user;
            })
        );
    }

    /**
     * LOGOUT
     */
    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('ferro_current_user');
        localStorage.removeItem('ferro_app_user');
        localStorage.removeItem('ferro_access_token');
        localStorage.removeItem('user-profile');
        localStorage.removeItem('ferroStartSeason');
        localStorage.removeItem('ferroFinishSeason');
        localStorage.removeItem('ferro-onboarding');
        sessionStorage.removeItem('last-team-visited');
        sessionStorage.removeItem('inbox-page-number');
        sessionStorage.removeItem('inbox-page-url');
        sessionStorage.removeItem('repository-page-number');
        sessionStorage.removeItem('repository-page-url');
        sessionStorage.removeItem('ferro-page');

        this.userSubject.next(null);
        this.appUserSubject.next(null);
        this.router.navigate(['/login']);
    }

    /**
     * REFRESH TOKEN
     */
    refreshToken() {
        const url = getCall('login', null);
        const httpData = new URLSearchParams();
        const storageUser = JSON.parse(localStorage.getItem('ferro_current_user'));
        httpData.set('client_id', environment.client_id);
        httpData.set('client_secret', environment.client_secret);
        httpData.set('grant_type', 'refresh_token');
        httpData.set('refresh_token', storageUser.refresh_token);

        return this._http.post<any>(url, httpData.toString(), this.getHeaderWOA()).pipe(
            map(user => {
                localStorage.setItem('ferro_current_user', JSON.stringify(user));
                this.userSubject.next(user);
                localStorage.setItem('ferro_access_token', user.access_token);
                return user;
            })
        );
    }
}
