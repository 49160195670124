export interface SeasonApiI {
    finish_date: string;
    id: number;
    initial_date: string;
    is_active: boolean;
    title: string;
}

export class Season {
    public finishDate: string;
    public startDate: string;
    constructor(input: SeasonApiI) {
        this.finishDate = input.finish_date;
        this.startDate = input.initial_date;
    }
}
