import { ActionsService } from '@services/api/actions/actions.service';
import { Component, OnInit } from '@angular/core';
import { SportsMinuteService } from '@services/api/sports-minute/sports-minute.service';
import { NewsModel } from '@models/sports_minute';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sports-minute',
    templateUrl: './sports-minute.component.html',
    styleUrls: ['./sports-minute.component.scss']
})
export class SportsMinuteComponent implements OnInit {
    public headerDescription =
        'Cada mes Sandra y Víctor cubrirán las mejores jugadas y las novedades más destacadas.';
    public newsList: NewsModel[] = [];
    private getNewsSubs: Subscription;

    constructor(
        private sportMinuteService: SportsMinuteService,
        private router: Router,
        private actionsService: ActionsService
    ) {}

    ngOnInit(): void {
        this.getNewsSubs = this.sportMinuteService.getNews().subscribe(response => {
            this.newsList = response;
        });
    }

    ngOnDestroy(): void {
        this.getNewsSubs.unsubscribe();
    }

    navigateToNews(news: NewsModel): void {
        this.sportMinuteService.selectNew(news);
        this.actionsService.postAction({ action: 'detail_news', id: news.id }).subscribe(() => {});
        this.router.navigateByUrl('/sports-details');
    }
}
