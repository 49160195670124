import { HttpClient } from '@angular/common/http';
import { HeadersService } from '@services/api/headers.service';
import { Injectable } from '@angular/core';
import { getCall } from '@services/api/callHelper.service';
import { ActionsI } from '@models/actions.model';

@Injectable({
    providedIn: 'root'
})
export class ActionsService extends HeadersService {
    constructor(public http: HttpClient) {
        super(http);
    }

    /** Sends to backend some actions that have been performed by the user */
    postAction(action: ActionsI) {
        const url = getCall('actions', null, {});
        return this.http.post(url, action, this.getHeaders());
    }
}
