import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HeadersService } from '@services/api/headers.service';
import { getCall } from '@services/api/callHelper.service';
import { ResponseAPI_News, NewsModel } from '@models/sports_minute';

@Injectable({
    providedIn: 'root'
})
export class SportsMinuteService extends HeadersService {
    public selectedNews: NewsModel;
    private url = getCall('news');

    constructor(public http: HttpClient) {
        super(http);
    }

    getNews() {
        return this.http.get(this.url, this.getHeaders()).pipe(
            map((response: ResponseAPI_News) => {
                return response.results;
            })
        );
    }

    selectNew(news: NewsModel) {
        this.selectedNews = news;
        this.markNewsAsViewed(news);
    }

    markNewsAsViewed(news: NewsModel) {
        return this.http.patch(`${this.url}${news.id}/`, {}, this.getHeaders()).subscribe(() => {
            news.read = true;
        });
    }
}
