<app-top-menu [isLogin]="true"></app-top-menu>
<div class="container pt-deskt-lg">
    <div class="row password-recovery-wrapper">
        <div class="col-8 col-md-5" *ngIf="!loading">
            <form [formGroup]="form" class="mbl-wrapper-strech">
                <div class="form-group" *ngIf="!token">
                    <label for="email">Email</label>
                    <input type="email" formControlName="email" name="email" placeholder="ejemplo@ejemplo.com">
                    <app-form-errors *ngIf="submitted" [errors]="f.email.errors"></app-form-errors>
                </div>
                <div class="form-group" *ngIf="token">
                    <label for="password">Nueva contraseña</label>
                    <input type="password" formControlName="password" name="password">
                    <app-form-errors *ngIf="submitted" [errors]="f.password.errors"></app-form-errors>
                </div>
                <div class="form-group" *ngIf="token">
                    <label for="password">Repetir contraseña</label>
                    <input type="password" formControlName="repeat" name="password">
                    <app-form-errors *ngIf="submitted" [errors]="f.repeat.errors"></app-form-errors>
                </div>
                
                <p *ngIf="!token">¿Has olvidado tu contraseña? Introduce la dirección de email y te enviaremos un correo para que puedas recuperarla.</p>
    
                <div class="form-group button-row">
                    <app-button *ngIf="!sending" [text]="'Recuperar'" [disabled]="!this.form.valid" (click)="onSubmit()"></app-button>
                </div>
    
                <div class="form-group">
                    <p class="text-center small back-button" (click)="onBackClick()">
                        <i class="material-icons notranslate">arrow_back</i>
                        Volver
                    </p>
                </div>
            </form>
        </div>
    </div>

    <div class="white-modal mt-lg" *ngIf="saved">
        <div class="white-modal__title text-center mb-md">
            ¡Contraseña restaurada!
        </div>
        <div class="white-modal__body">
            <p class="text-center mb-md">
                Ya puedes iniciar sesión con la nueva contraseña.
            </p>
            <div class="text-center">
                <app-button [text]="'Iniciar sesión'" [block]="true" [routerLink]="['/login']"></app-button>
            </div>
        </div>
    </div>
</div>