import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuComponent } from '@shared/menu/menu.component';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    @ViewChild(MenuComponent, { static: false })
    menuComponent: MenuComponent;

    constructor() {}

    ngOnInit(): void {}

    toggleMenu(): void {
        this.menuComponent.toggleMenu();
    }
}
