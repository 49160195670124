<div class="bg futball-field h-100">
    <div class="container page-with-top-bar pb-md full-height">
        <div class="row full-height">
            <div class="col-12">
                <div class="white-modal opacity mb-md delimited-section-modal mt-md" *ngIf="incident">
                    <div class="bordered-section">
                        <div class="title d-flex align-items-center">
                            <img src="assets/images/icons/reportar.svg" class="icon-small mr-xs" />
                            <span class="white-modal__title">Detalles de incidente</span>
                        </div>
                        <div class="mt-xs d-flex align-items-center">
                            <div
                                class="icon-round"
                                *ngIf="status != 1"
                                [ngClass]="{ pending: status === 0, completed: status === 2, rejected: status === 3 }"
                            ></div>
                            <p
                                class="bold ml-xs mb-0"
                                [ngClass]="statusText === 'No Válido' ? 'color-red' : 'color-grey'"
                            >
                                {{ statusText }}
                            </p>
                        </div>
                        <app-progress-bar
                            *ngIf="status === 1"
                            [userPoints]="incident.evaluation_questions_reported.reported"
                            [nextLevelPonits]="incident.evaluation_questions_reported.total"
                            [simple]="true"
                        ></app-progress-bar>
                        <div class="identifier mt-xs">ID: {{ incident.id_production_number }}</div>
                        <div class="evaluated-by-wrapper" *ngIf="evaluators.length > 0">
                            <div class="incident-question-label mt-sm mb-sm">
                                <img src="assets/images/icons/user.svg" class="mr-xs" />
                                <span class="bold">Evaluado por</span>
                            </div>
                            <div class="user-box-wrapper mb-sm" *ngFor="let evaluator of evaluators">
                                <div class="avatar" [style.background-image]="'url(' + evaluator.avatar + ')'"></div>
                                <div class="user-info">
                                    <p class="name">{{ evaluator.fullName }}</p>
                                    <p class="info">Expediente: {{ evaluator.expedient }}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bordered-section">
                        <div class="incident-question-label mt-sm mb-sm">
                            <img src="assets/images/icons/user.svg" class="mr-xs" />
                            <span class="bold">Reportado por:</span>
                        </div>
                        <div class="user-box-wrapper mb-sm">
                            <div class="avatar" [style.background-image]="'url(' + incident.user.avatar + ')'"></div>
                            <div class="user-info">
                                <p class="name">{{ incident.user.first_name }} {{ incident.user.last_name }}</p>
                            </div>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/briefcase.svg" class="mr-xs" />
                                <span class="bold">Unidad de negocio</span>
                            </div>
                            <p *ngIf="incident.business_unit">{{ incident.business_unit.name }}</p>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/briefcase.svg" class="mr-xs" />
                                <span class="bold">Dirección regional</span>
                            </div>
                            <p *ngIf="incident.regional_direction">{{ incident.regional_direction.name }}</p>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/briefcase.svg" class="mr-xs" />
                                <span class="bold">Delegación</span>
                            </div>
                            <p>{{ incident.contract.delegation }}</p>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/briefcase.svg" class="mr-xs" />
                                <span class="bold">Contrato / centro</span>
                            </div>
                            <p>{{ incident.contract.name }}</p>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/calendario.svg" class="mr-xs" />
                                <span class="bold">Fecha y hora de envío</span>
                            </div>
                            <p>{{ incident.created_at | date : 'dd/MM/yyyy HH:mm:ss' }}</p>
                        </div>
                    </div>

                    <div class="bordered-section">
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/calendario.svg" class="mr-xs" />
                                <span class="bold">Actividad</span>
                            </div>
                            <p [ngClass]="{ error: !incident.activity_incident }">
                                {{ incident.activity_incident ? incident.activity_incident.name : 'Sin especificar' }}
                            </p>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/hojas.svg" class="mr-xs" />
                                <span class="bold">Datos del incidente</span>
                            </div>
                            <div class="d-flex">
                                <p>Fecha: {{ incident.incident_date | date : 'dd/MM/yyyy' }} Hora:</p>
                                <p class="ml-xs" [ngClass]="{ error: !incident.incident_hour }">
                                    {{ incident.incident_hour ? incident.incident_hour : 'Sin especificar' }}
                                </p>
                            </div>
                            <div class="d-flex">
                                <p>Hora de la jornada:</p>
                                <p class="ml-xs" [ngClass]="{ error: !incident.work_day_hour }">
                                    {{ incident.work_day_hour ? incident.work_day_hour : 'Sin especificar' }}
                                </p>
                            </div>
                            <div class="d-flex">
                                <p>Lugar:</p>
                                <p class="ml-xs" [ngClass]="{ error: !incident.location }">
                                    {{ incident.location ? incident.location : 'Sin especificar' }}
                                </p>
                            </div>
                            <p>Descripción:</p>
                            <div class="white-box mb-sm">
                                {{ incident.description }}
                            </div>
                            <p>Adjuntos:</p>
                            <p class="error" *ngIf="!incident.description_files.length">Sin especificar</p>
                            <div class="files-wrapper" *ngIf="incident.description_files && incident.description_files.length">
                                <div *ngFor="let file of incident.description_files">
                                    <a *ngIf="file.incident_file" [href]="file.incident_file" download target="_blank">
                                        <div
                                            class="file-preview cursor"
                                            [ngClass]="{ file: !file.isImg }"
                                            [style.background-image]="'url(' + file.incident_file + ')'"
                                        ></div>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/flag.svg" class="mr-xs" />
                                <span class="bold">Riesgo potencial</span>
                            </div>
                            <p [ngClass]="{ error: !incident.risk_incident?.name }">
                                {{ incident.risk_incident?.name ? incident.risk_incident?.name : 'Sin especificar' }}
                            </p>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/causas-inmediatas.svg" class="mr-xs icon-small" />
                                <span class="bold">Causas inmediatas</span>
                            </div>
                            <p class="error" *ngIf="!incident.inmediate_causes.length">Sin especificar</p>
                            <p *ngFor="let cause of incident.inmediate_causes" class="causes-listing">
                                - {{ cause.name }}
                            </p>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/causas-raiz.svg" class="mr-xs icon-normal" />
                                <span class="bold">Causas raíz</span>
                            </div>
                            <p class="error" *ngIf="!incident.root_causes.length">Sin especificar</p>
                            <p *ngFor="let cause of incident.root_causes" class="causes-listing">- {{ cause.name }}</p>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/acciones-preventivas.svg" class="mr-xs icon-normal" />
                                <span class="bold">Acciones preventivas / correctivas</span>
                            </div>
                            <p class="white-box mb-sm" [ngClass]="{ error: !incident.preventive_actions }">
                                {{ incident.preventive_actions ? incident.preventive_actions : 'Sin especificar' }}
                            </p>
                            <div>
                                <p>Adjuntos:</p>
                                <p class="error" *ngIf="!incident.preventive_actions_files.length">Sin especificar</p>
                                <div class="files-wrapper" *ngIf="incident.preventive_actions_files.length">
                                    <div *ngFor="let file of incident.preventive_actions_files">
                                        <a [href]="file.incident_file" download target="_blank">
                                            <div
                                                class="file-preview cursor"
                                                [ngClass]="{ file: !file.isImg }"
                                                [style.background-image]="'url(' + file.incident_file + ')'"
                                            ></div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="incident-question-wrapper">
                            <div class="incident-question-label mt-sm">
                                <img src="assets/images/icons/hojas.svg" class="mr-xs" />
                                <span class="bold">Reunión de inicio y meta </span>
                            </div>
                            <div>
                                <p>Adjuntos:</p>
                                <p class="error" *ngIf="!incident.investigation_files.length">Sin especificar</p>
                                <div class="files-wrapper" *ngIf="incident.investigation_files.length">
                                    <div *ngFor="let file of incident.investigation_files">
                                        <a [href]="file.incident_file" download target="_blank">
                                            <div
                                                class="file-preview cursor"
                                                [ngClass]="{ file: !file.isImg }"
                                                [style.background-image]="'url(' + file.incident_file + ')'"
                                            ></div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bordered-section" *ngIf="status !== 4">
                        <div class="incident-question-label mt-sm mb-sm">
                            <img src="assets/images/icons/pelota.svg" class="mr-xs" />
                            <span class="bold">Puntuación</span>
                        </div>
                        <div class="d-flex status-score">
                            <div class="mt-xs d-flex align-items-center status">
                                <div
                                    class="icon-round"
                                    [ngClass]="{ pending: status === 0, 'in-review': status === 1, completed: status === 2 }"
                                ></div>
                                <p class="bold color-grey ml-xs mb-0">
                                    {{ status === 0 ? 'Pendiente de revisión' : '' }}
                                    {{ status === 1 ? 'En revisión' : '' }}
                                    {{ status === 2 ? 'Validada' : '' }}
                                </p>
                            </div>
                            <div class="d-flex score" *ngIf="status !== 0">
                                <div class="incident-status mr-xs">
                                    <div class="pb-ball"></div>
                                    <span class="completed">{{ incident.total_goals }} goles</span>
                                </div>
                            </div>
                        </div>

                        <div class="results" *ngIf="status === 2">
                            <div class="result">
                                <div class="row">
                                    <div class="col-8"></div>
                                    <div class="col-2 text-center">Puntos</div>
                                </div>
                            </div>
                            <div class="result">
                                <div class="row">
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="icon-wrapper icon-round completed">
                                            <img
                                                [src]="'assets/images/icons/silbar.svg'"
                                                class="icon-small"
                                                alt="entrevista"
                                            />
                                        </div>
                                        <p>¿Es válido?</p>
                                    </div>
                                    <div class="col-2">
                                        <div class="score bold text-center">1</div>
                                    </div>
                                </div>
                            </div>
                            <div class="result" *ngFor="let item of punctuation">
                                <div class="row">
                                    <div class="col-8 d-flex align-items-center">
                                        <div class="icon-wrapper">
                                            <img
                                                [src]="'assets/images/icons/' + item.icon + '.svg'"
                                                class="icon-small"
                                                alt="entrevista"
                                            />
                                        </div>
                                        <p>{{ item.question }}</p>
                                    </div>
                                    <div class="col-2">
                                        <div class="score bold text-center">{{ item.points }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="bordered-section" *ngIf="showComments && incident.comments_evaluation_incident">
                        <div class="incident-question-label mt-sm mb-sm">
                            <img src="assets/images/icons/entrevista.svg" class="mr-xs" />
                            <span class="bold">Comentario</span>
                        </div>
                        <div class="white-box mb-sm">
                            {{ incident.comments_evaluation_incident }}
                        </div>
                    </div>

                    <div class="incident-form-section delimited-section">
                        <div class="button-row d-flex justify-content-center">
                            <div class="d-flex justify-content-center" *ngIf="status !== 2 && status !== 3">
                                <app-button
                                    (click)="goBack()"
                                    class="mr-xs"
                                    [text]="'Volver'"
                                    [rounded]="true"
                                    [block]="true"
                                    [color]="'grey'"
                                    [textColor]="'white'"
                                ></app-button>
                                <app-button
                                    *ngIf="showEvaluateButton"
                                    [routerLink]="['/evaluate/' + reportId]"
                                    [text]="'Evaluar'"
                                    [rounded]="true"
                                    [block]="true"
                                    [icon]="'tarjeta-silbato'"
                                ></app-button>
                            </div>
                            <div class="text-center">
                                <app-button
                                    *ngIf="status === 2 || status === 3"
                                    [text]="'Volver'"
                                    [block]="true"
                                    [rounded]="true"
                                    (click)="goBack()"
                                ></app-button>
                                <app-button
                                    *ngIf="showDraftButton"
                                    [text]="'Rellenar borrador'"
                                    [block]="true"
                                    [rounded]="true"
                                    (click)="toDraft(incident.id)"
                                ></app-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
