import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '@helpers/helper/helper';
import { Team } from '@models/team.model';
import { TeamService } from '@services/api/team/team.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-team-members',
    templateUrl: './team-members.component.html',
    styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit {
    public team: Team;
    public teamId: number;

    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private teamService: TeamService,
        private helper: Helper
    ) {}

    ngOnInit(): void {
        this.teamId = this.route.snapshot.params.team_id;
        this.loadTeam();
    }

    loadTeam(): void {
        this.teamService.getTeams(this.teamId).subscribe(
            (response: any) => {
                this.team = response?.results ? response.results[0] : response;
                this.team = {
                    ...this.team,
                    teamStars: this.helper.getTeamStars(this.team.division_position)
                };
                this.team.get_team_members = this.team.get_team_members.filter(member => member.profile === 'player');
            },
            error => {
                console.log(error);
            }
        );
    }

    goBack(): void {
        this.location.back();
    }
}
