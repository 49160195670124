<div class="container">
    <div class="row">
        <div class="col-12 top-menu" [ngClass]="{'w-background': !isLogin}">
            <div class="row">
                <div class="col-6">
                    <div class="user-wrapper">
                        <img *ngIf="isLogin" src="assets/images/logos/logo-liga-negro.png" alt="liga" class="logo-liga">
                        <app-user-avatar *ngIf="!isLogin" (callback)="toggleMenu()"></app-user-avatar>
                    </div>
                </div>
                <div class="col-6">
                    <div class="logo-wrapper">
                        <img src="assets/images/logo-serveo.png" alt="serveo">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
