import { incidentResume } from '@models/incidents-resume.model';

export let incidentsTemplate: incidentResume[] = [
    {
        icon: 'situacion-riesgo',
        question: '',
        sortQuestion: 'Situación de riesgo',
        playerScore: 0,
        teamScore: 0
    },
    {
        icon: 'explicacion',
        question: '',
        sortQuestion: 'Explicación',
        playerScore: 0,
        teamScore: 0
    },
    {
        icon: 'graficos',
        question: '',
        sortQuestion: 'Gráficos',
        playerScore: 0,
        teamScore: 0
    },
    {
        icon: 'correcta-identificacion',
        question: '',
        sortQuestion: 'Identificación causas',
        playerScore: 0,
        teamScore: 0
    },
    {
        icon: 'evidencia-cierre',
        question: '',
        sortQuestion: 'Evidencias cierre acciones',
        playerScore: 0,
        teamScore: 0
    },
    {
        icon: 'otros-contratos',
        question: '',
        sortQuestion: 'Realizar reunión de inicio y meta',
        playerScore: 0,
        teamScore: 0
    },
    {
        icon: 'bombilla',
        question: '',
        sortQuestion: 'Calidad incidente y análisis',
        playerScore: 0,
        teamScore: 0
    },
];
