import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NotificationCard } from './notification-card.model';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent {
  @Input() notification: NotificationCard;
  @Output() onNextNotification = new EventEmitter<string | null>();

  handleNextNotification(route: string | null): void {
    this.onNextNotification.emit(route);
  }
}
