import { HttpClient } from '@angular/common/http';
import { HeadersService } from '@services/api/headers.service';
import { Injectable } from '@angular/core';
import { getCall } from '@services/api/callHelper.service';

@Injectable({
    providedIn: 'root'
})
export class PasswordRecoveryService extends HeadersService {
    constructor(public http: HttpClient) {
        super(http);
    }

    postEmail(email: string) {
        const url = getCall('passRecovery', null, {});

        return this.http.post(url, { email }, this.getHeaders());
    }

    postPassword(password: string, token: string) {
        const url = getCall('passRecoveryConfirm', token, {});

        return this.http.post(url, { new_password: password }, this.getHeaders());
    }
}
