import { Component, OnInit } from '@angular/core';
import { Helper } from '@helpers/helper/helper';
import { Team } from '@models/team.model';
import { TeamService } from '@services/api/team/team.service';

@Component({
    selector: 'app-trainer',
    templateUrl: './trainer.component.html',
    styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {
    public teams: Team[] = [];

    constructor(private teamService: TeamService, private helper: Helper) {}

    ngOnInit(): void {
        this.loadTeams();
    }

    loadTeams(): void {
        this.teamService.getTeams(null).subscribe(
            (response: any) => {
                this.teams = response.results;
                this.teams = this.teams.map(team => {
                    return {
                        ...team,
                        teamStars: this.helper.getTeamStars(team.division_position)
                    };
                });
            },
            error => {
                console.log(error);
            }
        );
    }
}
