import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html',
    styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent implements OnInit {
    // TODO: cambiar por "si existe el usuario / el usuario está logueado ... "
    @Input() isLogin: boolean = false;
    @Output() callback = new EventEmitter<any>();
    constructor() {}

    ngOnInit(): void {}

    toggleMenu(): void {
        this.callback.emit();
    }
}
