<div class="bg futball-field h-100">
    <div class="container page-with-top-bar pb-md full-height">
        <div class="row full-height">
            <div class="col-12">
                <div class="white-modal opacity mb-md delimited-section-modal mt-md">
                    <div class="bordered-section">
                        <div class="d-flex justify-content-between align-items-center">
                            <div class="title d-flex align-items-center">
                                <img src="assets/images/icons/repositorio.svg" class="icon-small mr-xs">
                                <span class="white-modal__title">Repositorio</span>
                            </div>
                            <div class="pointer">
                                <img src="assets/images/icons/close-icon.svg" [routerLink]="['/team']" >
                            </div>
                        </div>
                        <div class="inbox-filter-bar mt-sm">
                            <div>
                                Ver: <app-select (callback)="getSubFilter('persons', $event.key)" [isFilter]="true" [selectedItemDefault]="filterOptions[0]" [options]="filterOptions"></app-select>
                            </div>
                            <div *ngIf="showMyIncidentFilters || showAllIncidentFilters" class="inbox-incidents-filter">
                                <app-select
                                [options]="showMyIncidentFilters ? filterIncidents : filterAllIncidents"
                                [placeholder]="'Estado de la incidencia'"
                                [selectedKeyDefault]="filtersToSend['filter']"
                                (callback)="getSubFilter('filter', $event.key)">
                                </app-select>
                            </div>
                        </div>
                        <div class="more-filters mt-sm">
                            <div class="pointer mb-sm" (click)="toggleFilters()">
                                <img src="assets/images/icons/preferences.svg">
                                <span class="ml-xs mr-xs semi-bold">{{(showMoreFilters) ? 'Ocultar filtros': 'Mostrar filtros'}}</span>
                                <img src="assets/images/icons/chevron-down.svg" class="animate" [ngClass]="{'reverse': showMoreFilters}">
                            </div>
                            <div class="more-filters-wrapper" [ngClass]="{'d-none': !showMoreFilters}">
                                <div class="mb-xs" *ngIf="filterRegionalDirection">
                                    <app-select
                                        (callback)="getSubFilter('regional-direction', $event.key)"
                                        [selectedKeyDefault]="filters['regional-direction']"
                                        [placeholder]="'Dirección regional'"
                                        [options]="filterRegionalDirection"></app-select>
                                </div>
                                <div class="mb-xs" *ngIf="filterDelegation">
                                    <app-select
                                        (callback)="getSubFilter('delegation', $event.key)"
                                        [selectedKeyDefault]="filters['delegation']"
                                        [placeholder]="'Delegación'"
                                        [options]="filterDelegation"></app-select>
                                </div>
                                <div class="mb-xs" *ngIf="filterManagerPerson">
                                    <app-select
                                        (callback)="getSubFilter('user', $event.key)"
                                        [selectedKeyDefault]="filters['user']"
                                        [placeholder]="'Persona gestora'"
                                        [options]="filterManagerPerson"></app-select>
                                </div>
                                <div class="mb-xs">
                                    <app-select
                                        (callback)="getSubFilter('month', $event.key)"
                                        [selectedKeyDefault]="filters['month']"
                                        [placeholder]="'Mes de publicación'"
                                        [options]="filterMonthOptions"></app-select>
                                </div>
                                <div class="mb-xs">
                                    <app-select
                                        (callback)="getSubFilter('hipo', $event.key)"
                                        [selectedKeyDefault]="filters['hipo']"
                                        [placeholder]="'Tipo de Hipo'"
                                        [options]="filterHipoOptions"></app-select>
                                </div>
                                <div class="mb-xs">
                                    <app-select *ngIf="hipoRiskIncidentsOptions && riskIncidentsOptions"
                                        [selectedKeyDefault]="filters['risk_incident']"
                                        [placeholder]="'Elige uno de los riesgos'"
                                        [options]="(isHipo) ? hipoRiskIncidentsOptions : riskIncidentsOptions"
                                        (callback)="getSubFilter('risk_incident', $event.key)"></app-select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="incidents && TOTAL_INCIDENTS > MAX_INCIDENTS_PER_PAGE" class="incident-form-section delimited-section text-center">
                        <mat-paginator 
                            [length]="TOTAL_INCIDENTS"
                            [pageSize]="MAX_INCIDENTS_PER_PAGE"
                            [pageIndex]="currentPageNumber"
                            (page)="onPageChange($event)">
                        </mat-paginator>
                    </div>
                    <div class="two-cols-desktop" *ngIf="TOTAL_INCIDENTS > 0 && incidents">
                        <div class="bordered-section column d-flex justify-content-between align-items-center" *ngFor="let incident of incidents">
                            <div class="incident-block w-100 mt-sm mb-sm">
                                <div class="incident-block__header">
                                    <div class="white-panel w-100">
                                        <img src="assets/images/icons/hojas.svg" class="mr-xs">
                                        <div class="info-wrapper">
                                            <p class="bold m-0 incident-name">{{incident.risk_incident?.name}}</p>
                                            <div class="d-flex justify-content-between w-100">
                                                <span>{{(incident.risk_incident?.is_hipo) ? 'HIPO':'No HIPO'}}</span>
                                                <div class="d-flex align-items-center">
                                                    <img src="assets/images/icons/calendario.svg" class="icon-small mr-xs">
                                                    <span>{{incident.incident_date | date: 'dd/MM/yyyy'}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="player-avatar-wrapper mt-sm">
                                        <div class="player-avatar mr-xs" [style.background-image]="'url('+incident.user.avatar+')'"></div>
                                        <div class="player-info">
                                            <p class="name semi-bold">{{incident.user.first_name}} {{incident.user.last_name}}</p>
                                            <p class="player-type">{{incident.team?.name || incident.user.team}}</p>
                                        </div>
                                    </div>
                                    <div class="identifier mt-xs">ID: {{incident.id_production_number}}</div>
                                    <div class="desc mt-sm">
                                        <p>{{incident.description}}</p>
                                    </div>
                                    <div *ngIf="incident.status === 'rejected' && incident.not_valid_cause" class="desc mt-sm">
                                        <p class="error">Rechazada por: {{ incident.not_valid_cause }}</p>
                                    </div>
                                    <div class="incident-block__footer d-flex mt-sm">
                                        <div class="incident-status mr-xs">
                                            <div class="pb-ball"></div>
                                            <span class="bold">{{(incident.totalGoals === null ? '0' : incident.totalGoals) + ' goles'}}</span>

                                        </div>
                                        <app-button [text]="'Ver detalles'" [hasRightArrow]="true" [rounded]="true" [routerLink]="['/report/'+incident.id]"></app-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="incidents.length === 0">
                        <div class="no-results mb-md mt-md">
                            <img src="assets/images/icons/binoculars.svg">
                            <span class="bold">Sin resultados</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

