<div class="bg futball-field field h-100">
    <div class="container page-with-top-bar pb-md full-height">
        <div class="row full-height">
            <div class="col-12">
                <div class="info-block">
                    <p>El número total de goles por equipo se calcula teniendo en cuenta el número de personas que pertenecen al equipo para hacer la competición más justa para todos. El número de goles por persona es a título individual.</p>
                </div>
                <div class="d-flex justify-content-between align-items-center mt-sm">
                    <div>
                        <div class="back-button" (click)="goBack()">
                            <div class="arrow-back pointer"></div>
                            <p class="show-desktop">Volver</p>
                        </div>
                    </div>
                    <div>
                        <div class="trainer-wrapper mb-sm show-desktop d-flex justify-content-center mt-md" *ngIf="team && team?.trainer">
                            <div class="trainer d-flex">
                                <div class="avatar" [style.background-image]="'url('+team.trainer.avatar+')'"></div>
                                <div class="info ml-sm">
                                    <label class="mb-0">entrenador/a</label>
                                    <p class="mb-0">{{team.trainer.first_name}}</p>
                                </div>
                            </div>
                        </div>
                        <app-button [text]="'Ver estadio'" [rounded]="true" [block]="true" [routerLink]="['/team/'+teamId]" routerLinkActive="router-link-active" ></app-button>
                    </div>
                </div>
                <!-- TEAM -->
                <div class="teams mt-lg d-flex justify-content-center" *ngIf="team">
                    <div class="white-box team">
                        <div class="team-shield" [style.background-image]="'url('+team.picture+')'"></div>
                        <span class="semi-bold text-center">{{team.name}}</span>
                        <div class="stars-wrapper">
                            <div *ngFor="let starColor of team.teamStars">
                                <img [src]="'assets/images/icons/'+starColor+'-star.svg'">
                            </div>
                        </div>
                        <div class="goals-wrapper">
                            <div class="goals">
                                <div class="pb-ball"></div>
                                <span>{{team.total_goals}}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="trainer-wrapper show-mobile d-flex justify-content-center mt-md" *ngIf="team && team?.trainer">
                    <div class="trainer d-flex">
                        <div class="avatar" [style.background-image]="'url('+team.trainer.avatar+')'"></div>
                        <div class="info ml-sm">
                            <label class="mb-0">entrenador/a</label>
                            <p class="mb-0">{{team.trainer.first_name}}</p>
                        </div>
                    </div>
                </div>

                <div class="team-members" *ngIf="team">
                    <div class="team-member" *ngFor="let member of team.get_team_members">
                        <div class="white-box player">
                            <div class="team-shield avatar" [style.background-image]="'url('+member.avatar+')'"></div>
                            <p class="semi-bold mb-xs text-center">{{member.first_name}}{{member.last_name}}</p>
                            <div class="goals-wrapper">
                                <div class="goals">
                                    <div class="pb-ball"></div>
                                    <span>{{member.total_goals}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>