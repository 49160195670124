<div class="bg futball-field h-100 fixed" [ngClass]="{'d-none': !showNotifications}">
    <div class="container page-with-top-bar pb-md full-height">
        <app-notification-card 
            *ngIf="notificationCard" 
            [notification]="notificationCard" 
            (onNextNotification)="next($event)"
        >
            <div *ngIf="currentType === notificationType.GOAL && incidents" class="incidents-wrapper mt-md">
                <div class="incident mb-xs">
                    <img class="incident__icon icon-round completed" [src]="'assets/images/icons/silbar.svg'" [alt]="'silbar'">
                    <div class="name bold" [ngClass]="{large: !isMyTeam}">¿Es válida?</div>
                    <div class="p-score">1</div>
                </div>
                <div class="incident mb-xs" *ngFor="let incident of incidents">
                    <img class="incident__icon" [src]="'assets/images/icons/'+incident.icon+'.svg'" [alt]="incident.icon">
                    <div class="name bold" [ngClass]="{large: !isMyTeam}">{{incident.sortQuestion}}</div>
                    <div class="p-score">{{incident.playerScore}}</div>
                </div>
            </div>
        </app-notification-card>
    </div>
</div>