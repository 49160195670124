<div class="user-menu gradient" [ngClass]="{open: opened}" >
    <div class="container page-with-top-bar">
        <div class="user-menu__header mt-md">
            <div class="arrow-back pointer" (click)="close()"></div>
            <img src="assets/images/logos/logo-liga-negro.png" alt="liga" class="logo-liga">
        </div>
        <div class="user-menu__body mt-md">
            <div class="menu-item" *ngFor="let item of menu" (click)="manageMenuItemActions(item)">
                <div class="icon-wrapper">
                    <img [src]="'assets/images/icons/'+item.icon+'.svg'" class="mr-sm">
                </div>
                <span class="bold">{{item.name}}</span>
            </div>
        </div>
        <input type="file" class="hide" #inputFile (change)="onFileSelected($event.target.files)">
    </div>
</div>