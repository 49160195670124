import { AppUser } from '@models/user.model';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@services/api/login/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from '@helpers/helper/helper';
import { menuItem } from '@models/menu-item.model';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {
    public menu: menuItem[] = [
        {
            icon: 'information',
            name: 'Preguntas frecuentes',
            url: 'faq',
            action: 'close'
        },
        {
            icon: 'loading',
            name: 'Consultar tutorial',
            url: 'tutorial'
        },
        {
            icon: 'user',
            name: 'Editar foto de perfil',
            url: '',
            action: 'edit-user-avatar'
        },
        {
            icon: 'power',
            name: 'Cerrar sesión',
            url: '',
            action: 'close-session'
        }
    ];
    public opened: boolean = false;

    @ViewChild('inputFile') inputFile: ElementRef;

    constructor(
        private router: Router,
        private loginService: LoginService,
        private _snackBar: MatSnackBar,
        private helper: Helper
    ) {}

    ngOnInit(): void {}

    open(): void {
        this.opened = true;
    }

    close(): void {
        this.opened = false;
    }

    toggleMenu(): void {
        this.opened = !this.opened;
    }

    manageMenuItemActions(menuItem: menuItem): void {
        if (menuItem.url) {
            this.router.navigate(['/' + menuItem.url]);
        }
        if (menuItem.action) {
            switch (menuItem.action) {
                case 'edit-user-avatar':
                    this.inputFile.nativeElement.click();
                    break;
                case 'close-session':
                    this.loginService.logout();
                    break;
                case 'close':
                    this.close();
                    break;
                default:
                    break;
            }
        }
    }

    async onFileSelected(file: File) {
        this.helper.getImagePreview(file);
        let previewObject = <any>await this.helper.getImagePreview(file);
        let formData: FormData = new FormData();
        formData.append('avatar', file[0]);
        let user = this.loginService.currentAppUserValue;
        this.updateAvatar(user, formData, previewObject);
    }

    updateAvatar(user: AppUser, formData: FormData, previewObject: any): void {
        this.loginService.updateUserAvatar(user.identifier, formData).subscribe(
            () => {
                user.avatar = previewObject.preview;
                this.loginService.appUserSubject.next(user);
                this.openSnackBar('¡Avatar actualizado correctamente!', 'success-snackbar');
            },
            error => {
                console.log(error);
                this.openSnackBar('¡Algo ha salido mal! Intentalo más tarde.', 'error-snackbar');
            }
        );
    }

    openSnackBar(text: string, snackBarClass: 'success-snackbar' | 'error-snackbar') {
        this._snackBar.open(text, 'X', {
            duration: 3000,
            panelClass: [snackBarClass]
        });
    }
}
