<div class="bg futball-field h-100">
    <div class="container page-with-top-bar pb-md full-height">
        <div class="row full-height">
            <div class="col-12">
                <div class="white-modal opacity mb-md delimited-section-modal mt-md">
                
                    <div class="bordered-section">
                        <div class="mt-xs d-flex justify-content-end w-100">
                            <img src="assets/images/icons/close-icon.svg" class="pointer" [routerLink]="['/report/'+reportId]">
                        </div>
                        <div class="title d-flex align-items-center">
                            <span class="white-modal__title">Evalúa incidente</span>
                        </div>
                
                        <div class="evaluated-by-wrapper" *ngIf="evaluators.length === 0; else evaluated">
                            <div class="incident-question-label mt-sm mb-sm">
                                <span class="bold">¿Es válido este incidente?</span>
                            </div>
                            <div>
                                <app-select (callback)="changeValidStatus($event)" [icon]="'accept'" [searcher]="false" [options]="validReportOptions" [selectedItemDefault]="validReportOptions[0]"></app-select>
                            </div>
                        </div>
                        <ng-template #evaluated>
                            <p class="mb-0">Evaluador previo:</p>
                            <div class="user-box-wrapper mb-sm" *ngFor="let evaluator of evaluators">
                                <div class="avatar" [style.background-image]="'url('+evaluator.avatar+')'"></div>
                                <div class="user-info">
                                    <p class="name">{{evaluator.first_name}} {{evaluator.last_name}}</p>
                                    <p class="info">Expediente: {{evaluator.expedient}}</p>
                                </div>
                            </div>
                        </ng-template>
                    </div>
                
                    <ng-container *ngIf="form && valid">
                        <div class="bordered-section">
                            <div class="incident-question-label mt-sm mb-sm">
                                <span class="bold">¿Es un incidente HIPO?</span>
                            </div>
                            <div class="mb-xs">
                                <app-radio-button-group (callback)="setIsHipo($event)" [radioButtons]="radioButtonsDefault" [disabled]="riskAlreadyEvaluated"></app-radio-button-group>
                            </div>
                            <div *ngIf="isHipo" class="incident-question d-inline-block">
                                <app-select 
                                    *ngIf="riskIncidentsOptions"
                                    classOptions='risk-incidents-options'
                                    [placeholder]="'Elige uno de los riesgos'"
                                    [options]="riskIncidentsOptions"
                                    [selectedItemDefault]="selectedRisk"
                                    [disabled]="riskAlreadyEvaluated"
                                    (callback)="setRiskOption($event)"
                                ></app-select>
                            </div>
                        </div>
                        <div class="bordered-section" *ngFor="let section of form.sections">
                            <div class="incident-question-label mt-sm mb-sm">
                                <span class="bold">{{section.name}}</span>
                            </div>
                            <div *ngFor="let question of section.questions" class="mb-sm">
                                <p class="mb-0">{{question.name}}</p>
                                <ng-container *ngIf="question.evaluation_finished; else notEvaluated">
                                    <div class="question-evaluated">
                                        <img [src]="question.icon">
                                        <span>{{question.evaluation_finished.punctuation}}</span>
                                    </div>
                                </ng-container>
                                <ng-template #notEvaluated>
                                    <app-select (callback)="getQuestionResponse(question, $event)" [icon]="question.icon" [fromAPI]="true" [searcher]="false" [options]="question.options" [selectedItemDefault]="question.options[0]"></app-select>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>

                    <div class="bordered-section">
                        <div class="incident-question-label mt-sm mb-sm">
                            <span class="bold">Comentario</span>
                        </div>
                        <textarea class="white-box comment__text" name="comment" [(ngModel)]="evaluationComment"></textarea>
                    </div>
                
                
                    <div class="incident-form-section delimited-section">
                        <div class="button-row d-flex justify-content-center">
                            <app-button (click)="sendEvaluation()" *ngIf="!sending" [text]="'Finalizar'" [rounded]="true" [block]="true" [icon]="'tarjeta-silbato'"></app-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
