import { OptionInteface } from '@models/option.model';

export const filterHipoOptions: OptionInteface[] = [
    { key: '~clear~', value: '-' },
    { key: 'true', value: 'Tipo HIPO' },
    { key: 'false', value: 'Tipo NO HIPO' },
];

export const MORE_FILTERS_NAMES: string[] = [
    'regional-direction',
    'delegation',
    'user',
    'month',
    'hipo',
    'risk_incident'
];

export interface RepositoryFilters {
    'regional-direction'?: string;
    risk_incident?: string;
    delegation?: string;
    user?: string;
    month?: string;
    hipo?: string;
    persons: string;
    view: 'repository';
    limit?: string;
    offset?: string;
}

export const INBOX_MORE_FILTERS_NAMES: string[] = [
    'delegation',
    'user',
    'team'
]

export const filterMonthOptions: OptionInteface[] = [
    { key: '~clear~', value: '-' },
    { key: 1, value: 'Enero' },
    { key: 2, value: 'Febrero' },
    { key: 3, value: 'Marzo' },
    { key: 4, value: 'Abril' },
    { key: 5, value: 'Mayo' },
    { key: 6, value: 'Junio' },
    { key: 7, value: 'Julio' },
    { key: 8, value: 'Agosto' },
    { key: 9, value: 'Septiembre' },
    { key: 10, value: 'Octubre' },
    { key: 11, value: 'Noviembre' },
    { key: 12, value: 'Diciembre' },
];
