<div *ngIf="errors" class="input-help">
    <div *ngIf="errors.required" class="small error">
        Este campo es obligatorio.
    </div>
    <div *ngIf="errors.email" class="small error">
        Tiene que ser un email valido.
    </div>
    <div *ngIf="errors.mustMatch" class="small error">
        Las contraseñas no coinciden.
    </div>
</div>
