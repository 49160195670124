import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';

@Injectable({
    providedIn: 'root'
})
export class TeamService extends HeadersService {
    constructor(public http: HttpClient) {
        super(http);
    }

    getTeams(teamId: number) {
        const url = getCall('team', teamId, {});

        return this.http.get(url, this.getHeaders());
    }
}
