import { Component, OnInit } from '@angular/core';

import { faqCategory, FAQS } from './faqs';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public faqs: faqCategory[];

  constructor() { }

  ngOnInit(): void {
    this.faqs = FAQS;
  }


}
