import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';

@Injectable({
    providedIn: 'root'
})
export class EvaluationService extends HeadersService {
    constructor(public http: HttpClient) {
        super(http);
    }

    getQuestions(reportID: number) {
        const url = getCall('evaluationQuestions', reportID, {});

        return this.http.get(url, this.getHeaders());
    }

    evaluateIncident(incident: any) {
        const url = getCall('evaluationIncident', null, {});

        return this.http.post(url, incident, this.getHeaders());
    }
}
