import { Component, OnInit, ViewChild } from '@angular/core';
import { Incident } from '@models/incident.model';
import { OptionInteface } from '@models/option.model';
import { PaginatedAPIResponse } from '@models/paginated-api-response.model';
import { IncidentService } from '@services/api/incidents/incidents.service';
import { InboxFilters } from './inbox.conf';
import { Team } from '@models/team.model';
import { INBOX_MORE_FILTERS_NAMES } from '../repository/filters.conf';
import { MatPaginator } from '@angular/material/paginator';

@Component({
    selector: 'app-inbox',
    templateUrl: './inbox.component.html',
    styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {
    public filterOptions: OptionInteface[] = [
        { key: 'all', value: 'Todos' },
        { key: 'in_review', value: 'En revisión' },
        { key: 'pending', value: 'Pendientes' },
        { key: 'finished', value: 'Completos' },
        { key: 'rejected', value: 'Rechazados' }
    ];

    public incidents: Incident[];
    public MAX_INCIDENTS_PER_PAGE: number = 20;
    public TOTAL_INCIDENTS: number = 0;
    public pageSizeOptions: number[] = [10, 20, 50, 100];
    public currentPageNumber: number = 0;

    public nextInboxPage: string;
    public prevInboxPage: string;

    public showMoreFilters: boolean = false;
    public filterDelegation: OptionInteface[];
    public filterManagerPerson: OptionInteface[];
    public filterTeam: OptionInteface[];
    public clearOption: OptionInteface = { key: '~clear~', value: '-' };
    public filters: InboxFilters = {
        view: 'inbox'
    };

    public moreFiltersNames: string[] = INBOX_MORE_FILTERS_NAMES;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    constructor(private indicentService: IncidentService) {}

    ngOnInit(): void {
        sessionStorage.setItem('ferro-page', 'inbox');

        this.handleFilters();
        this.handlePageNavigation();
    }

    handlePageNavigation(): void {
        const pageNumber = sessionStorage.getItem('inbox-page-number');
        const pageUrl = sessionStorage.getItem('inbox-page-url');

        if ((pageNumber && pageUrl) && pageNumber != '0' && pageUrl != 'null') {
            this.currentPageNumber = parseInt(pageNumber);
            this.getInboxPaged(pageUrl);
        } else {
            this.getInbox(this.filters);
        }
    }

    handleFilters(): void {
        this.loadStoredFilters();
        this.getTeams();
        this.getDelegations({ referee: true });

        if (this.filters && this.moreFiltersNames.some(name => name in this.filters)) {
            //handle the calling of sub-filters that depend on the choice of some of the other sub-filters
            this.handleDependentSubFilters(this.filters);
        } else {
            this.getUsers();
        }
    }

    getInbox(filters: any = null): void {
        this.indicentService.getRepository(filters).subscribe(
            (response: PaginatedAPIResponse) => {
                this.loadIncidentsVars(response);
            },
            error => {
                console.log(error);
            }
        );
    }

    getInboxPaged(url: string): void {
        this.indicentService.getIncidentListPage(url).subscribe(
            (response: PaginatedAPIResponse) => {
                this.loadIncidentsVars(response);
            },
            error => {
                console.log(error);
            }
        );
    }

    loadIncidentsVars(response: PaginatedAPIResponse): void {
        this.nextInboxPage = response.next;
        this.prevInboxPage = response.previous;
        this.TOTAL_INCIDENTS = response.count;
        this.incidents = <Incident[]>response.results;
    }

    filterBy(event: OptionInteface) {
        if (event.key != 'all') {
            this.filters['filter'] = event.key;
        } else {
            delete this.filters['filter'];
        }
        //reset page number to 0
       this.setCurrentPage(0);

        this.getInbox(this.filters);
        localStorage.setItem('inbox-filters', JSON.stringify(this.filters));
    }

    onPageChange(event: any) {
        let pageUrl: string = event.previousPageIndex < event.pageIndex ? this.nextInboxPage : this.prevInboxPage;

        sessionStorage.setItem('inbox-page-url', pageUrl);
        sessionStorage.setItem('inbox-page-number', event.pageIndex);
        this.getInboxPaged(pageUrl);
    }

    toggleFilters(): void {
        this.showMoreFilters = !this.showMoreFilters;
    }

    getSubFilter(filter: string, key: any) {
        if (key !== '~clear~') {
            this.filters[filter] = key;
        } else if (this.filters[filter]) {
            delete this.filters[filter];
        }

        if (filter === 'delegation' || filter === 'team') {
            this.getUsers(this.filters);
        }
        //reset page number to 0
        this.setCurrentPage(0);
        this.getInbox(this.filters);
        localStorage.setItem('inbox-filters', JSON.stringify(this.filters));
    }

    handleDependentSubFilters(filters: InboxFilters): void {
        const paramsUsers = {};

        if (filters['delegation']) {
            paramsUsers['delegation'] = filters['delegation'];
        }
        
        if (filters['team']) {
            paramsUsers['team'] = filters['team'];
        }

        this.getUsers(paramsUsers);
    }

    loadStoredFilters(): void {
        const storedFilters = localStorage.getItem('inbox-filters');
        if (storedFilters) {
            const loadedFilters = JSON.parse(storedFilters);
            this.filters = { ...this.filters, ...loadedFilters };
            const isSelectedMoreFilters = this.moreFiltersNames.some(name => loadedFilters[name]);
            this.showMoreFilters = isSelectedMoreFilters;
        }
    }

    getUsers(params: InboxFilters | {} = {}): void {
        this.indicentService.getRepositoryPlayers(params).subscribe(
            (response: any) => {
                this.filterManagerPerson = response.map(user => {
                    const username: string = `${user.first_name} ${user.last_name}`;
                    return { key: user.identifier, value: username };
                });
                this.filterManagerPerson.unshift(this.clearOption);
            },
            error => {
                console.log(error);
            }
        );
    }

    getTeams(params: InboxFilters | {} = {}): void {
        this.indicentService.getInboxTeams(params).subscribe(
            (response: Team[]) => {
                this.filterTeam = response.map(team => {
                    return { key: team.id, value: team.name};
                });
                this.filterTeam.unshift(this.clearOption);
            },
            error => {
                console.log(error);
            }
        );
    }

    getDelegations(params: InboxFilters | {}  = {}): void {
        this.indicentService.getDelegations(params).subscribe(
            (response: any) => {
                this.filterDelegation = response.map(delegation => {
                    return { key: delegation.id, value: delegation.name };
                });
                this.filterDelegation.unshift(this.clearOption);
            },
            error => {
                console.log(error);
            }
        );
    }

    setCurrentPage(pageNumber: number): void {
        if (this.paginator) {
        this.currentPageNumber = pageNumber;
          this.paginator.pageIndex = pageNumber;
          sessionStorage.setItem('inbox-page-number', null);
        }
    }
}
